.h-pagination-1 {
    .pagination {
        margin-bottom: 0;
    }
    .page-item {
        &.active {
            .page-link {
                color: $white;
                background: transparent linear-gradient(180deg, #244271 0%, #1B3A60 100%) 0% 0% no-repeat padding-box;
                // border-color: #0d6efd;
            }
        }
        &.first, &.last{
            .page-link {
                background-color: transparent;
                color: #6E6C6C;
            }
        }
        .page-link {
            border: 0;
            border-radius: 50%;
            margin: 0 .15rem;
            padding: 0.10rem 0.4rem;
            font-size: .75rem;
            font-weight: bold;
            background-color: #E6E6E6;
            color: #B5B5B5;
        }
    } 
}
