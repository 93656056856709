.header {
  background-color: #977fd0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 13px 20px 10px 20px;
}
.headertext {
  color: white;
  font-size: 18px;
  /* padding-left: 20px; */
}
.footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 13px 20px 10px 20px;
  background-color: white;
  border:1px solid #e1e1e1;
  // opacity: 0.5;
}
.video-player div {
  // border-radius: 20px;
  // border: 1px solid #999;
  // border-radius: 20px;
}
.fr {
  float: right;
  padding: 0px 10px 0px 10px;
}
.fl{
  float: left;
}
i {
  /* font-size: 22px; */
  cursor: pointer;
}
.call {
  text-align: center;
  background-color: #fff;
  height: 100%;
}

.player-container {
  padding: 20px;
  text-align: initial;
}

.button-group {
  padding: 20px;
}

.call-form {
  padding: 20px;
}

// input,
// button {
//   margin-right: 10px;
// }

/* ///////////////flexbox */

.videocontainer {
  // display: flex;
  // flex:1;
  // flex-direction: row;

  .video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // max-height:550px;
    // max-width:420px;
    justify-content: space-around;
    background: #dfdfdf;
    border: 1px solid #ccc;
    // min-height: 400px;
    // position: relative;

    // .local-player-wrapper{
    //   position: absolute;
    //   bottom: 5px;
    //   right: 5px;
    //   z-index: 100;
    // }
    // .remote-people{
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   align-items: center;
    // }
  }
  .localcss{
    position: absolute;
    z-index: 999;
    bottom: 50px;
    right:30px;
  }
  .remote-player-wrapper{
    padding-bottom: 5px;
    padding-right: 5px;
  }
}

.video-player{
  video{
    object-fit: contain !important;
  }
}

#alertsDropdown{
  i{
    font-size: 20px !important;
  }
}