.btn {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 500;
    &.btn-primary-blue {
        background: transparent linear-gradient(94deg, #244271 0%, #1B3A60 100%) 0% 0% no-repeat padding-box !important;
        color: $white !important;
    }
    &.btn-primary-grey {
        background: #8B8B8B 0% 0% no-repeat padding-box;
        color: $white !important;
    }
    &.btn-primary-white{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: $blue-600;
    }
    &.btn-outline-grey {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #5F5F5F;
    }
    &.btn-outline-blue {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #244170;
        border-radius: 6px;
    }
    &.btn-link-txt {
        color: $white;
        text-decoration: none;
    }

}
.btn-large {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}
.btn-small {
    padding: .25rem 1.8rem;
}
.btn-rounded {
    border-radius: .5rem;
}
.btn-shadow {
    box-shadow: 0px 2px 6px #00000012;
    letter-spacing: 0.29px;
}