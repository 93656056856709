.sidebar {
    width: $sidebar-collapsed-width;
    // min-height: 100vh;
    .nav-item {
        position: relative;
        &:last-child {
            margin-bottom: 1rem;
        }
        .nav-link {
            text-align: center;
            padding: 0.75rem 1rem;
            width: $sidebar-collapsed-width;
            span {
                font-size: 0.65rem;
                display: block;
                margin-left: 2px;
            }
        }
        &.active {
            .nav-link {
                font-weight: 700;
            }
        }
        .h-collapse {
            position: absolute;
            left: calc(3.5rem + 1.5rem / 2);
            z-index: 1;
            top: 2px;
            .collapse-inner {
                // box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
                border-radius: 0.35rem;
                // background-color: $blue-100;
                // min-width: 8rem;
            }
        }
        .collapsing {
            display: none;
            transition: none;
        }
        .collapse,
        .collapsing {
            .collapse-inner {
                font-size: .75rem;
                .btn-link-txt {
                    text-align: left;
                    &[data-toggle="collapse"] {
                        &::before {
                            width: 1rem;
                            text-align: center;
                            vertical-align: 0;
                            border: 0;
                            font-weight: 900;
                            content: '\f0d7';
                            font-family: 'Font Awesome 5 Free';
                            color: $white
                        }
                        &.collapsed::before {
                            content: '\f0da';
                        }
                    }
                }
                .collapse-item {
                    padding: 0.1rem 1.5rem;
                    // margin: 0 0.5rem;
                    display: block;
                    color: $white;
                    text-decoration: none;
                    font-size: 0.75rem;
                    white-space: nowrap;
                    cursor: pointer;
                    &:hover {
                        // background-color: $gray-200;
                    }
                    &:active {
                        // background-color: $gray-300;
                    }
                    &.active {
                        // color: $primary;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    #sidebarToggle {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
        &:focus {
            outline: none;
        }
    }
    &.toggled {
        width: 0 !important;
        overflow: hidden;
    }
    .sidebar-brand {
        padding: 1.5rem .75rem;
        z-index: 1;
        .sidebar-brand-text {
            display: none;
        }
    }
    hr.sidebar-divider {
        margin: .5rem 1rem 1.5rem 1rem;
    }
    .sidebar-heading {
        text-align: center;
        padding: 0 1rem;
        font-weight: 800;
        font-size: 0.65rem;
        // @extend .text-uppercase;
    }
}
@media (max-width: 575.98px) {
    .sidebar {
        &.toggled {
            .nav-item {
                .h-collapse {
                    position: absolute;
                    left: calc(3.5rem + 1.5rem / 2);
                    z-index: 1;
                    top: 2px;
                    -webkit-animation-name: growIn;
                    animation-name: growIn;
                    -webkit-animation-duration: 200ms;
                    animation-duration: 200ms;
                    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
                    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
                    // padding: 1rem;                   
                }
                .collapse-inner {
                    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
                    border-radius: 0.35rem;
                    background-color: $blue-100;
                    // min-width: 8rem;
                    // padding: .75rem;
                }
            }
        }
    }
    .sidebar-dark #sidebarToggle {
        background-image: url(../../../img/close.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
        width: 1.8rem;
        height: 1.8rem;
    }
    .sidebar .nav-item .nav-link span {
        display: none !important;
    }
}
@media (min-width: 768px) {
    .sidebar {
        width: $sidebar-base-width !important;
        .nav-item {
            // Accordion
            .collapse {
                position: relative;
                left: 0;
                z-index: 1;
                top: 0;
                animation: none;
                .collapse-inner {
                    border-radius: 0;
                    box-shadow: none;
                }
            }
            .collapsing {
                display: block;
                // transition: $transition-collapse;
            }
            .collapse,
            .collapsing {
                margin: 0 0rem;
            }
            .nav-link {
                display: block;
                width: 100%;
                text-align: left;
                padding: .5rem;
                margin-bottom: .65rem;
                // margin: 0 .75rem;
                width: $sidebar-base-width;
                i {
                    font-size: 0.85rem;
                    margin-right: 0.25rem;
                }
                span {
                    font-size: 0.75rem;
                    display: inline;
                    font-weight: 600;
                }
            }
        }
        .sidebar-heading {
            text-align: left;
        }
        &.toggled {
            overflow: visible;
            width: $sidebar-collapsed-width !important;
            .nav-item {
                .h-collapse {
                    position: absolute;
                    left: calc(3.5rem + 1.5rem / 2);
                    z-index: 1;
                    top: 2px;
                    -webkit-animation-name: growIn;
                    animation-name: growIn;
                    -webkit-animation-duration: 200ms;
                    animation-duration: 200ms;
                    -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
                    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
                    // padding: 1rem;                   
                }
                .collapse-inner {
                    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
                    border-radius: 0.35rem;
                    background-color: $blue-100;
                    // min-width: 8rem;
                    // padding: .75rem;
                }
                .collapsing {
                    display: none;
                    transition: none;
                }
                .collapse,
                .collapsing {
                    margin: 0;
                }
                &:last-child {
                    margin-bottom: 1rem;
                }
                .nav-link {
                    text-align: center;
                    padding: 0.75rem 1rem;
                    width: $sidebar-collapsed-width;
                    span {
                        font-size: 0.65rem;
                        display: none !important;
                    }
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

// Sidebar Dark
.sidebar-dark {
    .sidebar-brand {
        color: $white;
    }
    hr.sidebar-divider {
        border-top: 1px solid fade-out($white, 0.25);
    }
    .sidebar-heading {
        color: fade-out($white, 0.6);
    }
    .nav-item {
        .nav-link {
            color: fade-out($white, 0.5);
            letter-spacing: 0.023rem;
            font-size: .85rem;
            font-weight: 500;
            i {
                color: fade-out($white, 0.7);
            }
            &:active,
            &:focus,
            &:hover {
                color: $white;
                i {
                    color: $white;
                }
            }
        }
        &.active {
            .nav-link {
                color: $white;
                background-color: fade-out($black, 0.77);
                i {
                    color: $white;
                }
            }
        }
    }
    #sidebarToggle {
        background-image: url(../../../img/menu.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        cursor: pointer;
    }
    &.toggled #sidebarToggle {
        background-image: url(../../../img/menu-reverse.svg);
        width: 2rem;
        height: 2rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
    }
}
