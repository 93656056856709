
.login-sp-wrapper {
    padding: 5rem !important;
    background-color: #e5edf8;
    .bg-login-left {
        background: url(../../../img/login-bg.svg);
        background-position: center center;
        // background-size: contain;
        background-repeat: no-repeat;
        height: 450px;
    }
    .card {
        @include card-inner;
        border: 0;
        .card-body {
            padding: 2rem;
        }
    }
    .form-link {
        font-size: .875rem;
        color: $blue-200;
    }
    .social-login {
        ul {
            .static-link-txt {
                color: $grey-700;
                font-size: .75rem;
                font-weight: 700;
            }
            margin-bottom: 0;
            .social-link {
                color: $grey-700;
                font-size: .8rem;
                font-weight: 500;
                text-decoration: none;
                display: inline-flex;
            }
            .social-icon {
                width: 1rem;
                height: 1rem;
                display: inline-flex;
                align-items: center;
                align-content: center;
                &.facebook {
                    background-image: url(../../../img/facebook.svg);
                }
                &.google {
                    background-image: url(../../../img/google.svg);
                }
            }

        }

    }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .login-sp-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.5rem;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .login-sp-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.5rem;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .login-sp-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.5rem;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .login-sp-wrapper {
        padding: 1.5rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.2rem;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}