
.h-form-1 {
    .form-group {
        // margin-bottom: 1.5rem;
        label {
            font-size: .875rem;
            color: #9a9a9a;
            margin-bottom: .15rem;
        }
        .form-control {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-radius: 0;
            color: $grey-900;
            font-weight: 500;
            border-color: $grey-600;
            padding: 0.25rem 0;
            height: calc(1.5em + .5rem + 2px);
            font-size: .85rem;
            &:focus {
                box-shadow: none;
            }
        }
        .input-group-text {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid $grey-600;
            border-radius: 0;
            color: $grey-600;
        }
        &.is-invalid {
            .form-text, label {color: $orange-primary;}
            .form-control, .input-group-text {border-color: $orange-primary;}
        }
    }
    .form-control-plaintext {
        font-size: .85rem;
        letter-spacing: 0.42px;
        color: #2F2F2F;
        &.active {
            color: #53814D;
        }
    }
}
