.hekmaDatePicker .react-date-picker__wrapper {
  border: none !important;
  border-bottom: 1px solid !important;
  width: 100% !important;
}

.searchButtonWidth {
  width: 0% !important;
  float: right !important;
}
.searchicon {
  margin-left: -36px !important;
  margin-top: 8px !important;
  border: none !important;
}

.table_courses td,
.table_courses th {
  padding: 0.5rem !important;
  vertical-align: top;
  border-top: none !important;
}
.thead_color {
  background: aliceblue;
  font-size: 14px;
}
.td_action {
  display: inline-flex !important;
  width: 100% !important;
}
.action_div {
  cursor: pointer !important;
  padding: 0px 10px 0px !important;
}

.siteNavsubbar {
  a {
    color: #244271;
  }
  .nav-link.active {
    border-bottom: 2px solid #244271;
    color: #244271;
    font-weight: bold;
  }
}
.sponsorNavsubbar {
  a {
    color: #244271;
  }
  .nav-link.active {
    border-bottom: 2px solid #244271;
    color: #244271;
    font-weight: bold;
  }
}

.cardSubmenu {
  // border-top-right-radius: 0.75rem !important;
  // border-top-left-radius: 0.75rem !important;
}
.primary-header {
  font-size: 22px !important;
  font-weight: 700 !important;
}
.MuiButton-textPrimary {
  color: #244271 !important;
}
.MuiButton-textSecondary {
  color: red !important;
  // background-color:#244271 !important;
  // border-radius: 50px !important;
}
.subNavText {
  cursor: pointer;
  width: max-content !important;
  color: #22406d;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.sitenamecss {
  cursor: pointer;
  color: #22406d;
  font-size: 16px;
  font-weight: 600;
}
.viewCourseLinks {
  width: 100% !important;
  border-collapse: separate;
  thead {
    font-size: 13px !important;
  }
  tr th {
    font-weight: 100 !important;
    background: #eff5ff !important;
    padding: 10px !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6 !important;
    // text-align: center;
  }
  tbody {
    font-size: 14px !important;
    tr td {
      padding: 10px !important;
      word-break: break-all;
    }
    // tr td{
    //   box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
    // }
  }
}
.viewScheduleMatrix {
  width: 100% !important;
  border-collapse: separate;
  border-spacing: 5px 0;
  thead {
    font-size: 13px !important;
  }
  tr th {
    font-weight: 100 !important;
    background: #eff5ff !important;
    padding: 10px !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6 !important;
    // text-align: center;
  }
  .eventMatrixth th:nth-child(even) {
    background-color: #f5f5f5 !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventMatrixth th:nth-child(odd) {
    background-color: white !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventText {
    text-align: center;
    /* color: #cdcdcd; */
    font-weight: 600 !important;
    /* margin: 88px !important; */
    padding: 17px !important;
  }
  tbody {
    font-size: 14px !important;
    tr td {
      padding: 7px !important;
      word-break: break-all;
    }
    // tr td{
    //   box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
    // }
  }
}
.tabs_events {
  .MuiTabs-flexContainer {
    background-color: white !important;
    // border-top-right-radius: 0.75rem !important;
    // border-top-left-radius: 0.75rem !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #244271 !important;
}

//events
.arrowMark {
  align-items: center;
  justify-content: center;
  display: flex;
}

.newEventCss {
  align-items: center;
  justify-content: start;
  display: flex;
  cursor: pointer;
}
// .input-group>.input-group-append>.btn{
//   border-radius: 0.313rem !important;
// }
.activeEvents {
  margin-top: 22px;
  margin-bottom: 12px;
}
.secondary-header {
  font-family: "Roboto Slab" !important;
}
button:focus {
  outline: none !important;
}
.fixedWidth {
  scrollbar-width: thin !important;
  scrollbar-color: red !important;
  max-height: 300px;
  overflow-y: auto;
  width: auto;
}
.fixedWidthFunnelCriteria{
  height: 400px;
  background-color: white;
  overflow-y: auto;
  width: auto;
}
.fixedHeight {
  max-height: 250px;
  overflow-y: auto;
  
}
.phoneerr {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
}
.dropdown-item:active {
  background-color: #b6cfff !important;
}
.globalsearch .form-control:focus {
  border-color: #94bbe2 !important;
  box-shadow: none;
}
.dropdown-item {
  padding: 0.25rem 0.75rem !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.textRed {
  color: red !important;
  cursor: pointer !important;
  margin-left: 5px !important;
}

//pdf preview
.pdfmodal {
  // .react-pdf__Document {
  //   .react-pdf__Page {
  //     .react-pdf__Page__canvas {
  //       height: 500px !important;
  //       // border: 1px solid black;
  //     }
  //   }
  // }
  img {
    cursor: pointer;
  }
}
.imgmodal {
  img {
    height: 500px !important;
  }
}
// .previewpdf {
//   .react-pdf__Document {
//     .react-pdf__Page {
//       .react-pdf__Page__canvas {
//         height: 20% !important;
//         width: 50% !important;
//         border: 1px solid black;
//       }
//     }
//     .react-pdf__Page__textContent {
//       // position: absolute;
//       top: 0% !important;
//       left: 0% !important;
//       width: 0px !important;
//       height: 0px !important;
//       display: none !important;
//       // color: transparent;
//       // transform: translate(-50%, -50%) rotate(0deg);
//       // pointer-events: none;
//     }
//   }
// }
.nodata {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}
.studyper {
  table {
    tbody {
      tr {
        cursor: default !important;
      }
    }
  }
}
