.hekmaDatePicker .react-date-picker__wrapper {
  border: none !important;
  border-bottom: 1px solid !important;
  width: 100% !important;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.searchButtonWidth {
  width: 0% !important;
  float: right !important;
}
.searchicon {
  margin-left: -36px !important;
  margin-top: 8px !important;
  border: none !important;
}

.table_courses td,
.table_courses th {
  padding: 0.5rem !important;
  vertical-align: top;
  border-top: none !important;
}
.thead_color {
  background: aliceblue;
}
.segment_color {
  background: aliceblue;
  padding: 5px;
}
.events_css {
  background-color: lightgrey;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  /* margin-top: 15px; */
  display: inline-block;
}
.td_action {
  display: inline-flex !important;
  width: 100% !important;
}
.action_div {
  cursor: pointer !important;
  padding: 0px 10px 0px !important;
}
.segmentsX_icon {
  cursor: pointer !important;
  padding: 10px 0px 0px 12px !important;
}
.siteNavsubbar {
  a {
    color: #244271;
  }
  .nav-link.active {
    border-bottom: 2px solid #244271;
    color: #244271 !important;
    font-weight: bold;
  }
}

.cardSubmenu {
  // border-top-right-radius: 0.75rem !important;
  // border-top-left-radius: 0.75rem !important;
}
.primary-header {
  font-size: 22px !important;
  font-weight: 700 !important;
}
.MuiButton-textPrimary {
  color: #244271 !important;
}
.MuiButton-textSecondary {
  color: red !important;
  // background-color:#244271 !important;
  // border-radius: 50px !important;
}
.subNavText {
  cursor: pointer;
  width: max-content !important;
  color: #22406d;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.sitenamecss {
  cursor: pointer;
  color: #22406d;
  font-size: 16px;
  font-weight: 600;
}
.mhDashboard{
  width:none !important;
  thead{
    display: block !important;
  }
  tbody{
    display: block !important;
    max-height: 380px !important;;
    overflow-y:auto !important;
  }
}
.viewCourseLinks {
  width: 100% !important;
  border-collapse: separate;
  border-spacing: 5px 0;
  thead {
    font-size: 13px !important;
  }
  tr th {
    font-weight: 100 !important;
    background: #eff5ff !important;
    padding: 10px !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6 !important;
    // text-align: center;
  }
  .eventMatrixth th:nth-child(even) {
    background-color: #f5f5f5 !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventMatrixth th:nth-child(odd) {
    background-color: white !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventText {
    text-align: center;
    /* color: #cdcdcd; */
    font-weight: 600 !important;
    /* margin: 88px !important; */
    padding: 17px !important;
  }
  tbody {
    font-size: 14px !important;
    tr td {
      padding: 10px !important;
      word-break: break-all;
    }
    // tr td{
    //   box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
    // }
  }
}
.linebreak {
  word-break: break-all;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #244271 !important;
}
.MuiChip-root {
  border-radius: 5px !important;
  background-color: #f2f3f7 !important;
}

.thead_color td:first-child {
  border-radius: 6px 0 0 0;
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
}

.thead_color td:last-child {
  border-radius: 0 6px 0 0;
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
}
.hrcss hr {
  margin-top: 0px !important;
}

.font14 {
  font-size: 14px;
}

.secondary-header {
  font-family: "Roboto Slab" !important;
}
.plabel {
  color: #9a9a9a !important;
  font-size: 12px;
}
.phoneerr {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
}

.siteselectioncss .card {
  margin-top: 50px;
  margin-left: 20px;
  border-top: 1px solid #bcc5d2;
  border-bottom: 1px solid #bcc5d2;
  .card-body {
    display: flex;
    width: 100%;
    align-items: center;
    img {
      padding-right: 10px;
      border-right: 3px solid #576e8d;
      margin-right: 10px;
    }
    form {
      width: 100%;
    }
  }
}

.google {
  button {
    box-shadow: none !important;
    border: 1px solid #ddd !important;
    font-size: 14px;
    div {
      padding: 6px !important;
    }
    span {
      padding: 0px !important;
      padding-right: 10px !important;
      font-size: 14px !important;
    }
  }
}
.kep-login-facebook {
  min-width: 90px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  // margin-left: 15px !important;
  height: 35px !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #23416f;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.textRed {
  color: red !important;
  cursor: pointer !important;
  margin-left: 5px !important;
}

//pdf preview
.pdfmodal {
  // .react-pdf__Document {
  //   .react-pdf__Page {
  //     .react-pdf__Page__canvas {
  //       height: 500px !important;
  //       // border: 1px solid black;
  //     }
  //   }
  // }
  img {
    cursor: pointer;
  }
}
.imgmodal {
  img {
    height: 500px !important;
  }
}

.nodata {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.errMsg {
  color: #f44336;
  padding-top: 15px;
  padding-left: 72px;
  font-size: 0.75rem;
}
.fileuploadclick {
  font-weight: 500;
  text-decoration: underline;
  color: #23416f;
}

//dosage table css
.dosageModal {
  max-width: 90%;
}

.dosageModal .modal-content {
  height: 100%;
}

.completed i:before {
  color: #669933;
}

.latelyCompleted i:before {
  color: #ffa500;
}

.notCompleted i:before {
  color: #cc0000;
}

.notStarted i:before {
  color: #ccc;
}

.metricsTable {
  margin-top: 15px;
}

.printEmail {
  margin-bottom: 15px;
}

.printEmail > * {
  margin-left: 10px;
}

.printEmail > button {
  padding: 2px 3px;
  min-width: 80px;
}

.container-fluid.dosageTable {
  padding: 0px;
}
.col50 {
  width: 50%;
}
.col30 {
  width: 30%;
}
.col70 {
  width: 70%;
}

.container-fluid.dosageTable .col {
  padding: 0px;
}

.metricsTable .table thead tr th,
.metricsTable .table tr td {
  text-align: center;
}

.dosageTablePage {
  padding: 25px !important;
}

.metricsTable .table thead tr th:first-child,
.metricsTable .table tr td:first-child {
  text-align: left;
}

.svgDosage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgDosage svg {
  height: 12px;
  width: 12px;
}

.dosageIcon path {
  display: block;
  transition: all 0.3s;
}

.backToOldScr {
  text-align: right;
  margin-top: 10px;
}

.backToOldScr label {
  color: #214b7d;
  text-decoration: underline;
  cursor: pointer;
}

.backToOldScr label:hover {
  text-decoration: none;
}

.appointmentSucess {
  text-align: center;
  .appointmenttext {
    font-size: 15px !important;
  }
  .datedisplay {
    font-weight: 600;
    color: #1d3c63;
    font-size: 15px !important;
  }
  .calendaricons {
    display: flex;
    flex-direction: row;
    .item {
      flex-grow: 1;
      font-size: 13px !important;
    }
  }
}

//sponsor app starts

.viewSponsorCourseLinks {
  width: 100% !important;
  border-collapse: separate;
  thead {
    font-size: 13px !important;
  }
  tr th {
    font-weight: 100 !important;
    background: #eff5ff !important;
    padding: 10px !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6 !important;
    // text-align: center;
  }
  tbody {
    font-size: 14px !important;
    tr td {
      padding: 7px !important;
      word-break: break-all;
    }
    // tr td{
    //   box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
    // }
  }
}
.viewScheduleMatrix {
  width: 100% !important;
  border-collapse: separate;
  border-spacing: 5px 0;
  thead {
    font-size: 13px !important;
  }
  tr th {
    font-weight: 100 !important;
    background: #eff5ff !important;
    padding: 10px !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6 !important;
    // text-align: center;
  }
  .eventMatrixth th:nth-child(even) {
    background-color: #f5f5f5 !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventMatrixth th:nth-child(odd) {
    background-color: white !important;
    border: none;
    border-bottom: 0.5px solid #dee2e6;
  }
  .eventText {
    text-align: center;
    /* color: #cdcdcd; */
    font-weight: 600 !important;
    /* margin: 88px !important; */
    padding: 17px !important;
  }
  tbody {
    font-size: 14px !important;
    tr td {
      padding: 7px !important;
      word-break: break-all;
    }
    // tr td{
    //   box-shadow: 0 2px 1px 0 rgba(0,0,0,0.1);
    // }
  }
}
.tabs_events {
  .MuiTabs-flexContainer {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    // border-top-right-radius: 0.75rem !important;
    // border-top-left-radius: 0.75rem !important;
  }
}

//events
.arrowMark {
  align-items: center;
  justify-content: center;
  display: flex;
}

.newEventCss {
  align-items: center;
  justify-content: start;
  display: flex;
  cursor: pointer;
}
// .input-group>.input-group-append>.btn{
//   border-radius: 0.313rem !important;
// }
.activeEvents {
  margin-top: 22px;
  margin-bottom: 12px;
}
.hekmacolor{
  color:#23416F;
  font-weight: bold
}

button:focus {
  outline: none !important;
}
.fixedWidth {
  scrollbar-width: thin !important;
  scrollbar-color: red !important;
  height: 300px;
  overflow-y: auto;
  width: auto;
}
.dropdown-item:active {
  background-color: #b6cfff !important;
}
.globalsearch .form-control:focus {
  border-color: #94bbe2 !important;
  box-shadow: none;
}

.studyper {
  table {
    tbody {
      tr {
        cursor: default !important;
      }
    }
  }
}

.fa-lg {
  font-size: 1.1rem !important;
}

.fullwidth {
  width: 100%;
}

.deleteIcon {
  color: #ce581b;
}

.delIconColor {
  color: #001440 !important;

  :hover {
    color: red !important;
  }
}

.panel-primary-wrap .card .card-page-header {
  margin-bottom: 1rem !important;
}

.siteNavsubbar .nav-item .nav-link {
  color: #9a9a9a;
  font-size: 0.8rem;
  // font-weight: bold;
  padding: 0.6rem 1rem;
}

#wrapper #content-wrapper {
  overflow-x: visible;
}

.recentItems {
  display: block;

  .recentdata {
    cursor: pointer;
  }
}

body.sidebar-toggled {
  .recentItems {
    display: none;
  }
}

.inputSearchBtn {
  border-radius: 0px 7px 7px 0px;
}

.sponsorNavsubbar {
  li.nav-item {
    margin: 0.6rem 1rem 0px 1rem;

    a.nav-link {
      padding: 0px 0px 0.3rem 0px;
    }
  }
}

.headerfontcss {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 500;
}

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 600 !important;
}

// .MuiTypography-body1 {
//   font-size: 13px !important;
//   // font-weight: 500 !important
// }
.filedetails .posrelative .tpopover a span:hover {
  font-weight: 500;
}
.center {
  text-align: center;
}

.video-player__icon-container__play-icon {
  max-height: 60%;
}
.filestatus label {
  // margin-left: 1px;
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.filehistorycss {
  color: white;
  background: #203e69;
  margin-bottom: 1rem !important;
  padding: 9px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 200;
  padding-left: 15px;
}

.emaillayout {
  img {
    margin: 15px !important;
    height: 200px !important;
  }
}

.mailimg img {
  height: 90% !important;
}
.cardimages {
  img {
    width: 120px !important;
    height: 120px !important;
  }
}
.bb {
  border-bottom: 1px solid gray;
}
.imgrow {
  margin: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .imggrid {
    width: 150px;
    display: block;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.bordercssemail {
  border: 1px solid #e9e8e8;
}
.bordercssemail:hover {
  border: 1px solid #203e69;
}

.recentitems {
  color: white !important;
  i {
    color: white !important;
  }
}
.mt2rem {
  margin-top: 2rem !important;
}

.mt-0{
  margin-top: 0px !important; 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-check {
  font-size: 12px !important;
  color: #9a9a9a;
}

//errmsg for multiselect users page
.multiselecterrmsg {
  color: red;
  margin-left: auto;
  margin-top: 5px;
  padding-bottom: 5px;
}
.htmldata {
  img {
    max-width: 100% !important;
  }
  iframe {
    max-width: 100% !important;
  }
}
.segmenttext {
  display: flex;
  align-items: center;
  text-align: center;
}

.siteImg {
  text-align: center;
  margin: auto;
  img {
    max-width: 100%;
  }
}
.cur {
  cursor: pointer;
}

.forgotpwd {
 
  a {
    color: #9a9a9a !important;
    text-decoration: none !important;
    font-size: 12px !important;
  }
  a:hover {
    color: #203f6a !important;
  }
}
.MuiTable-root {
  tfoot tr td.MuiTableCell-root {
    padding: 0px !important;
  }
}

.green {
  color: rgb(1, 71, 1);
  font-weight: 700;
}

.pwdEye {
  padding: 0px !important;
  :hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  svg {
    font-size: 20px !important;
  }
}

.userdetailscss {
  display: flex;
  align-items: center;
  text-align: center;
}

.alertCss {
  a {
    border-bottom: 1px solid lightgrey;
  }
  a:last-child {
    border: none !important;
  }
}

.createdcss {
  // border: 1px solid red !important;
  background-color: #fa706e;
  color: white !important;
  padding: 5px;
  // cursor: pointer;
}
.viewedcss {
  // background-color:#fd706c  ;
  color: #0e71eb !important;
  padding: 5px;
  // cursor: pointer;
}

.appointmentcss {
  background-color: rgb(166, 206, 166);
  padding: 5px;
}
.cr {
  cursor: pointer;
}

.caltooltipheader {
  // padding: 0px 5px 0px 5px;
  color: gray;
  border-bottom: 1px solid #e9e5e5;
  // background: #b0c9e7;
  font-weight: bold;
}
.doctooltip {
  .tooltip {
    pointer-events: none;
  }
}

.iconsmodal {
  font-size: 15px;
  padding-right: 10px;
  .fa-pencil-alt {
    padding-right: 10px;
  }
  .fa-trash {
    color: red;
  }
}
.opensquare {
  height: 100% !important;
  // padding-bottom: 50px;
  .clinicaltrailinput {
    // backgound:url('../../../img/BG2.jpg');
    option {
      background: white;
    }
    svg {
      color: #9a9a9a;
      font-size: 20px;
    }
    .inputfield {
      background: white;
    }
    .text {
      // float: right;
      font-size: 14px;
      // font-weight: 500;
      // margin-top: 5px;
      color: rgb(63, 173, 216);
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .infodiv {
    // margin-top: 0rem !important;
    // margin-bottom: 3rem !important;
    .info {
      border: 1px solid lightgrey;
      padding: 20px;
      border-radius: 15px;
      color: slategray;
      background: white;
    }
  }
}
.marketpir {
  .pirpatient {
    border: 1px solid lightgray;
    padding: 10px;
    background: white;
  }
  .psearch {
    border: 1px solid lightgrey;
    padding: 8px;
    background: white;
    border-radius: 15px;
  }
}

.bg-w {
  background-color: white !important;
}

.shoppingitem {
  display: flex;
}
.w100 {
  width: 100% !important;
}

.opensqData{
  // background-image:url('../../../img/BG2.jpg');
  // margin-top: -67px;
  margin: -67px -25px 0px -25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95vh;
  background-image:url('../../../img/opensquarebg.jpg');
  background-size: cover;
  background-repeat: repeat-y;
}
.fc .fc-button-group > .fc-button{
  background:#8da0d4 !important;
  border:1px solid #4b66ab;
  height: 28px;
  font-size: 12px
}
.fc .fc-button-group > .fc-button.fc-button-active{
  background:#344e8f !important;
}
.fc .fc-button-primary{
  background:#4b66ab !important;
  height: 28px;
  font-size: 12px
}
.opensquaredropdown ul li{
  background-color: white !important;
}
// .fc-button-group{
//   button{
    

//   }
//   .fc-button-active{
//     background-color:#6b7076 !important;
//   }
// }