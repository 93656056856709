
$white: #FFFFFF;
$black: #000000;
$blue-100: #244271;
$blue-200: #234370;
$blue-300: #23416F;
$blue-400: #223E6D;
$blue-500: #1B3A60;
$blue-600: #244170;

$grey-100: #F4F4F4;
$grey-200: #0000002E;
$grey-300: #BABABA;
$grey-400: #BEBEBE;
$grey-500: #CBCBCB;
$grey-600: #D0D0D0;
$grey-700: #8D8D8D;
$grey-800: #626262;
$grey-900: #3E3E3E;

$header-grey: #9D9D9D;
$label-grey-100: #919191;
$label-grey-200: #2C2C2C;
$text-grey: #9A9A9A;
$placeholder-600: #797979;

$orange-primary: #FB7F3E;

// Fonts
$font-size-14: .875rem;
$font-size-12: 0.75rem;

$sidebar-base-width: 9.25rem;
$sidebar-collapsed-width: 3.5rem;
$topbar-base-height: 4.375rem !default;


// custom variables
$hekErrorColor : #f44336;
$bgColor : #f5f5f5;
$inputBoxColor : #f5f6f7;
$cellPadding : 15px 10px;
$mgthColor : #9A9A9A;
