.s_outerWidth_common {
    width          : 100%;
    max-width      : 100%;
    overflow       : hidden;
    display        : flex;
    justify-content: center;
    align-items    : center;
    border-radius: 0.25rem;

    .left,
    .right {
        cursor: pointer;
        &.disable{
            cursor: not-allowed;
            color: #ccc;
        }
    }

    .s_innerWidth {

        overflow: hidden;
        margin  : 0 auto;

        ul {
            list-style: none;
            padding   : 0px;
            margin    : 0px;
            display   : inline-flex;
            transition: all .3s;
            justify-content: center;
            margin-left: 0px;

            li {
                //width          : 150px;
                // height         : 150px;
                // border         : 1px solid red;
                margin         : 1px 12px;
                display        : flex;
                //justify-content: center;
                align-items    : center;

                img {
                    max-width : 100%;
                    max-height: 100%;
                }
            }
        }
    }
}