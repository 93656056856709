.listing-wrap {
    .card {
        @include card-bg;
        border: none;
        // margin-bottom: 1.5rem;
        .card-header {
            background-color: #fff;
            border: 0;
            padding: 1.5rem 1.25rem;
            margin-bottom: -1.4rem;
            &:first-child {
                border-radius: .75rem;
            }
        }
        .input-group {
            .input-group-prepend {
                .input-group-text {
                    background: transparent;
                    border-color: #F2F2F2;
                }
            }
            .form-control {
                padding: 0.25rem 0;
                border-left: 0;
                border-color: #F2F2F2;
            }
        }
    }
}
.label-footer {
    font-size: .75rem;
    font-weight: bold;
    color: $label-grey-100;
}
.label-footer-value {
    font-size: .75rem;
    font-weight: bold;
    color: $label-grey-200;
}
.btn-group {
    .h-btn-txt {
        padding: .25rem 0;
    }
}
.h-btn-txt {
    font-size: .75rem;
    font-weight: bold;
    color: $blue-600;
}
.label-grey-txt {
    font-size: .85rem;
    color: #7E7E7E;
    letter-spacing: 0.67px;
}
.label-blue-txt {
    font-size: .85rem;
    color: #1C4FA0;
    letter-spacing: 0.67px;
}