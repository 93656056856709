.h-tabs-1 {
    .nav-fill .nav-item {
        text-align: left;
    }
    .nav-tabs .nav-link {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 2px solid $grey-100;
        // color: $grey-400;
        font-weight: 500;
        color: $blue-300;
        padding: 0.375rem 0.75rem;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        border-color: $blue-100;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: $blue-300;
        font-weight: 500;
    }
}

.h-tabs-2 {
    .nav-pills {
        .nav-link {
            color: $text-grey;
            font-size: .85rem;
            font-weight: bold;
            padding: 0.20rem 0.80rem;
            &.active {
                background: transparent linear-gradient(113deg, #244271 0%, #1B3A60 100%) 0% 0% no-repeat padding-box;
                color: $white;
                border-radius: 0.313rem;
                font-size: .85rem;
                font-weight: bold;
            }
        }
    }
}


