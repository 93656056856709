.panel-primary-wrap {
    .card {
        @include card-bg;
        border: 0;
        margin-bottom: 2rem;
        .card-page-header {
            font-size: .85rem;
            font-weight: bold;
            letter-spacing: 0.42px;
            color: $grey-800;
            text-transform: uppercase;
            padding-bottom: .5rem;
            border-bottom: 1px solid #EAEAEA;
            margin-bottom: 2rem;
        }
        .card-page-sub-header {
            font-size: .85rem;
            font-weight: bold;
            text-transform: capitalize;
            color: $placeholder-600;
            margin-bottom: 1rem;
        }
        .card-body {
            padding:2.2rem;
            // padding-bottom: 1rem !important;
            // padding-left:  2.2rem;
            // padding-right:  2.2rem;
            // padding-top: 2.2rem
        }
    }

}
.card-rounded {
    @include card-bg;
    border: 0;
    // margin-bottom: 2rem;
    padding: 0.75rem;
}
@media  only screen  and (max-width: 1100px) {
    .siteNavbar{
        .nav-item{
            margin-bottom: 5px !important
        }
    }
   }
.card-top-rounded {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001F;
    border-radius: 12px 12px 4px 4px;
    padding: .5rem 1.5rem 0 1.5rem;
}
.card-body-rounded {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001F;
    border-radius: 4px 4px 12px 12px;
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .panel-primary-wrap {
        .card {
            .card-body {
                padding: 1.5rem;
                // padding-bottom: 1rem !important;
            }
        }
    
    }
}