
// for Icon Modification

/* dataTables CSS modification & positioning */
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0 !important;
  content: "" !important;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0 !important;
  content: "" !important;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}
  
table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: absolute !important;
    top: 12px !important;
    right: 8px !important;
    display: block !important;
    font-family: 'Font Awesome 5 Free' !important;
}
table.dataTable thead th.sorting:after {
    content: "\f0dc" !important;
    color: #ddd !important;
    font-size: 0.8em !important;
    padding-top: 0.12em !important;
}
table.dataTable thead th.sorting_asc:after {
    content: "\f0de" !important;
}
table.dataTable thead th.sorting_desc:after {
    content: "\f0dd" !important;
}

// f077 chevron up
// f078 chevron down
.h-table {
    thead {
        tr {
            background-color: #F0F5FF;
            th {
                color: #9A9A9A;
                font-size: .85rem;
                font-weight: bold;
                border-bottom-color: transparent;
            }
        }
    }
    td {
        color: $grey-800;
        font-size: .85rem;
        font-weight: 500;
        padding: .85rem 0.5rem;
        .t-link {
            color: $blue-100;
        }
        .t-mail-link {
            color: $blue-200;
        }
        .h-delete {
            color: $grey-300;
        }
    }
    tr{
        &:last-child {
            td {
                border-bottom: 0;
            }
        }
    }
}