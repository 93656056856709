.navbar {
    padding: 0.5rem 0;
}

.container-fluid {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.contentBody {
    background-color: $bgColor;
    width: 100%;
    // padding-bottom  : 20px;
}

.loginForm {
    & > div {
        width: 100%;
    }
}

.hekError {
    background: $hekErrorColor;
    color: $white;
    border-radius: 3px;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .icon {
        padding: 0px 5px;
    }
}

.fullScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: rgba(140, 109, 109, 0.48);
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1000;

    .progressCircle {
        color: #edf8fb;
    }
}
.funnelCss{
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 1000;
.funnelCircle{ 
    // color: #edf8fb;
}
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f8f8f8 !important;
}

.MuiFormControl-root {
    .MuiInputBase-root.MuiInput-underline:hover::before {
        border-bottom: 1px solid !important;
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
        border-bottom: 1px solid #3f51b5 !important;
    }

    .MuiInputBase-root.MuiInput-underline.Mui-error:after {
        border-bottom: 1px solid #f44336 !important;
    }
}

.inputBox {
    & > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .MuiInputBase-input {
        font-size: 14px;
    }

    .MuiFormLabel-root {
        font-size: 14px;
    }
}

form {
    .must:after {
        content: "*";
        color: $hekErrorColor;
        padding-left: 3px;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        & > * {
            margin-left: 6px;
        }
    }
}

.bodyContainer {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.hekmaGrid {
    .card {
        background-color: $bgColor;
        box-shadow: none;
        border-radius: 0px;
    }

    .MuiPaper-rounded {
        border-radius: 15px;
        box-shadow: 0px 0px 4px #00000015 !important;
    }

    .MuiTableHead-root {
        background-color: #f0f5ff;
    }

    // .MuiToolbar-gutters {
    //     padding: 20px;
    // }

    .MuiTableRow-head {
        th:first-child {
            padding-left: 20px !important;
        }

        th:last-child {
            padding-right: 20px !important;
        }

        th {
            background-color: #f0f5ff;
            padding: 8px 10px;
            color: $mgthColor;

            // text-transform: uppercase;
            & :last-child {
                svg {
                    display: none;
                }
            }
        }
    }

    .MuiTableRow-root {
        td.MuiTableCell-root {
            // padding: $cellPadding;
            padding: 17px 10px !important;
            // color: #213f6b !important;
        }

        td:first-child {
            padding-left: 20px !important;
        }
    }

    .MuiTableRow-root.MuiTableRow-footer {
        td.MuiTableCell-root {
            padding: 0px;
        }
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #dee2e6;
    }

    // tr:last-child {
    //     td {
    //         border-bottom: none;
    //     }
    // }

    .MuiFlatPagination-root {
        margin-top: 20px;
    }

    .hekmaGridBox {
        position: relative;

        .gridSearchField {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 15px;

            //border-bottom: 0.5px solid #9c9c9c;
            i {
                cursor: pointer;
                color: #c7c4c4;
            }

            svg {
                color: #9a9a9a;
            }
        }

        .MuiFormControl-root {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: #9c9c9c;
            }

            .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
                border-bottom: 1px solid #9c9c9c !important;
            }
        }

        // .MuiToolbar-gutters{
        //     margin-bottom: 20px;
        // }
    }
}

.fa-lg {
    font-size: 1.1rem !important;
}

.fullwidth {
    width: 100%;
}

.deleteIcon {
    color: #ce581b;
}

.delIconColor {
    color: #001440 !important;

    :hover {
        color: red !important;
    }
}

.hekmaDatePicker {
}

.panel-primary-wrap .card .card-page-header {
    margin-bottom: 1rem !important;
}

.siteNavsubbar .nav-item .nav-link {
    color: #9a9a9a;
    font-size: 0.9rem;
    // font-weight: bold;
    padding: 0.6rem 1rem;
}

.sponsorNavsubbar .nav-item .nav-link {
    color: black;
    font-size: 0.9rem;
    // font-weight: bold;
    padding: 0.6rem 1rem;
}

#wrapper #content-wrapper {
    overflow-x: visible;
}

.recentItems {
    display: block;

    // a {
    //     cursor: pointer;
    // }
}

body.sidebar-toggled {
    .recentItems {
        display: none;
    }
}

.inputSearchBtn {
    border-radius: 0px 7px 7px 0px;
}

.siteNavsubbar {
    li.nav-item {
        margin: 0.6rem 1rem 0px 1rem;

        a.nav-link {
            padding: 0px 0px 0.3rem 0px;
        }
    }
}

#accordionSidebar.sidebar-dark {
    .nav-item {
        //line-height: 0px;
        a {
            //margin-bottom: 5px;
        }

        .nav-link.active {
            color: #ffffff;

            i {
                color: #ffffff;
            }
        }
    }
}

.dashboardContainer {
    * {
        font-size: 14px;
    }

    .text {
        text-anchor: middle;
    }

    .circle {
        fill: orange;
        stroke: orange;
        fill-opacity: 0.5;
    }

    .axis line {
        fill: none;
        stroke: #ddd;
        shape-rendering: crispEdges;
    }

    .axis path {
        fill: none;
    }

    .axis text {
        font-size: 0.7em;
        fill: #555;
        font-family: sans-serif;
    }

    .focus {
        fill: none;
        stroke: green;
    }

    .focus circle {
        fill: #f1f3f3;
        stroke: #6f257f;
        stroke-width: 5px;
    }

    .hover-line {
        stroke: #6f257f;
        stroke-width: 2px;
        stroke-dasharray: 3, 3;
    }

    .dashboardTile {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
    }

    .dtitle {
    }
}

.headerfontcss {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 500;
}

.linebreak {
    word-break: break-all;
}

// .google {
//     button {
//         div {
//             padding: 6px !important;
//         }
//         span {
//             padding: 0px !important;
//             padding-right: 10px !important;
//             font-size: 14px !important;
//         }
//     }
// }
// .kep-login-facebook {
//     min-width: 87px !important;
//     text-transform: capitalize !important;
//     font-size: 14px !important;
//     font-weight: 400px !important;
//     border-radius: 2px !important;
// }
.errMsg {
    // color: #f44336;
    //  margin-left: auto;
    // float: right;
    // padding-bottom: 5px;
    // font-size: 0.75rem;
    color: #f44336;
    /* float         : right; */
    /* padding-bottom: 5px; */
    padding-top: 15px;
    padding-left: 72px;
    font-size: 0.75rem;
    /* text-align    : right; */
    // float: right;
}

.MuiTab-textColorPrimary.Mui-selected {
    font-weight: 600 !important;
}

.fileuploadclick {
    font-weight: 500;
    text-decoration: underline;
    color: #23416f;
}

.google {
    button {
        div {
            padding: 6px !important;
        }

        span {
            padding: 0px !important;
            padding-right: 10px !important;
            font-size: 14px !important;
        }
    }
}

.kep-login-facebook {
    min-width: 87px !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border-radius: 2px !important;
    margin-left: 15px !important;
    height: 35px !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #23416f;
}

/* calendar fix*/
.datetime-trigger {
    .datetime-picker {
        right: 0px;
    }
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
    right: 10px;
    left: auto;
}
