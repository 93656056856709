.dashboardContainer .searchCriteriaPanel {
    overflow: auto;
    max-height: 20rem;
    // min-height: 20rem;
    border: 1px solid #ccc;

    ul {
        padding-left: 0.2rem;
        list-style: none;

        li {
            margin-bottom: 0.5rem;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.criteriaTextfield {
    .MuiInputBase-root {
        padding: 1rem;
        font-size: 0.9rem;
        line-height: 1.3rem;
    }
}

form.add-criteria-modal {
    position: relative;
    margin-top: 1.2rem;

    .delete {
        position: absolute;
        top: -33px;
        cursor: pointer;
        right: 0px;
    }
}

.edit-pencil {
    transition: 0.5s all;

    &:hover {
        i {
            color: #1b3a60;
            cursor: pointer;
        }
    }
}

.dashboardContainer .searchcriteriacontainer {
    .pircontent.pirpanel-2 {
        // background: transparent;
        // border: 1px solid #ccc;
    }

    .pirsection {
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        color: #c5c5c5;
        line-height: 30px;

        &:nth-child(even) {
            background-color: #fff;
            padding: 10px;
            border-radius: 4px;
        }

        button.border-0 {
            background: none;
            padding: 0px 4px;
        }
        .fa-plus-circle {
            color: #878787;
        }
        .fa-times-circle {
            color: #ee512a;
        }
    }

    .inputdata {
        display: flex;
        align-items: baseline;

        &.inc i {
            color: green;
        }

        &.exc i {
            color: red;
        }
    }

    .pir-detail {
        column-count: 2;
        display: block;

        & > div {
            padding: 0.2rem;

            & span:last-child {
                padding: 2px 5px;
                border-radius: 4px;
            }
        }
    }

    .unknown {
        color: red;
        // .unknown{
        //     background-color: red;
        //     color: #fff;
        // }
    }

    .demographics,
    .Demographic,
    .Demographics {
        color: #3116e8;
        // .demographics,.Demographic,.Demographics{
        //     background-color: #2fbbab;
        //     color: #fff;
        // }
    }

    .Value {
        color: #f80304;
        // .Value{
        //     background-color: #8e7dff;
        //     color: #fff;
        // }
    }

    .diagnostics {
        color: #3317e9;
        // .diagnostics{
        //     background-color: #3317e9;
        //     color: #fff;
        // }
    }

    .LabDetails {
        color: #f70692;
    }

    .Condition {
        color: #2fb87e;
        // .Condition{
        //     background-color: #e00084;
        //     color: #fff;
        // }
    }
    .Gender {
        color: #f5d104;
    }
    .Education {
        color: #a602c7;
    }
    .Language {
        color: #06a2f7;
       
    }
    .Race {
        color: brown;
    }
    .Location {
        color: #b84889;
    }
    .Values {
        color: red;
    }

    .Measurement {
        color: #fd9720;
        // .Measurement{
        //     background-color: #fd9720;
        //     color: #fff;
        // }
    }

    .Procedure {
        color: #8e7dff;
        // .Procedure{
        //     background-color: #8e7dff;
        //     color: #fff;
        // }
    }

    .Observation {
        color: #a6e22d;
        // .Observation{
        //     background-color: #a6e22d;
        //     color: #fff;
        // }
    }

    .Drug {
        color: #fb951e;
        // .Drug{
        //     background-color: #a90147;
        //     color: #fff;
        // }
    }

    .Duration {
        color: #8e7dff;
        // .Temporal{
        //     background-color: #8e7dff;
        //     color: #fff;
        // }
    }

    .Procedure {
        color: #8e7dff;
        // .Procedure{
        //     background-color: #8e7dff;
        //     color: #fff;
        // }
    }

    .highlightvalue {
        background-color: #8e7dff;
        color: #fff !important;
        padding: 4px;
        border-radius: 4px;

        * {
            color: #fff !important;
        }
    }

    .categoriesLegends {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;

        i {
            font-size: 0.7rem;
        }

        li {
            margin-right: 5px;
        }
    }

    aside.titleClmn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    span.link {
        cursor: pointer;
        border: 1px solid;
        padding: 1px 4px;
        border-radius: 4px;
    }

    #pirrightpanel {
        position: relative;
        left: 7px;
    }

    #addBtnRightPanel {
        position: absolute;
        left: 0px;
        top: -30px;
        display: none;
    }

    #pirleftpanel {
        position: relative;
    }

    .ex-collapse {
        position: absolute;
        right: 1px;
        top: 45%;
        border-left: none;
        padding: 20px 1px;
        cursor: pointer;
        border-radius: 0 4px 4px 0px;
        font-family: "Font Awesome 5 Free";
        z-index: 999;
        box-shadow: 0px 0px 2px 2px #dfe4ec;
        color: #6c757d;
        width: 12px;
    }

    #expandClmn {
        display: none;
        width: 50px;
        background-color: #dfe4ec;
        border-radius: 5px;
        position: relative;
        min-height: 250px;

        &.showexpand {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 10px;
            margin-right: 15px;
        }

        .to-right {
            right: -10px;
        }

        .expandleft-collapse {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }

    #pirleftcontent {
        .fa-plus-circle {
            color: #22406e;
        }
    }
}

.deleteEntities {
    display: flex;

    label {
        width: 48%;
    }
}

.editEntities {
    cursor: pointer;
    padding: 2px 5px;
}

.editActive {
    border-radius: 4px;
    background-color: rgba(80, 80, 204, 0.7);
    color: #fff;
}

#d_study_progress {
    li {
        width: 30px;
        display: block;
    }

    .numberdiv {
        position: relative;
        display: flex;
        align-items: center;
        width: 200%;
        margin-top: -15px;

        .Pending {
            background-color: gray;
        }

        .Visited {
            background-color: #43b143;
        }

        p {
            margin: 0px;
            width: 100%;
            border: 1px solid #ccc;
        }

        span {
            position: absolute;
            background: #7467e1;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            align-items: center;
            color: #fff;
        }
    }

    li:last-child p {
        width: 0px;
    }
}

[contenteditable] {
    outline: 0px solid transparent;
}

[contentEditable] {
    outline: 0px solid transparent;
}

.delete-edit {
    :hover {
        color: #234170;
    }
    color: #c5c5c5;
    cursor: pointer;
}

.contentEdit {
    span {
        border: none;
        color: #4d4dc7;
        outline: 0px;
    }

    .delete-edit {
        display: block !important;
        color: rgb(243, 19, 19);

        i {
            cursor: pointer;
        }
    }
}

.MuiBackdrop-root {
    background-color: rgba(154, 152, 156, 0.5) !important;
}

.unlinkentity {
    position: absolute;
    top: -25px;
    right: 0;
    cursor: pointer;
    color: #203f6a;
    font-size: 15px;
}
.customizedprogressbar {
    & > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
