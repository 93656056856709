.view-account-wrap {
    .card {
        @include card-bg;
        border: 0;
        margin-bottom: 2rem;
        .card-header {
            padding: 1rem 2rem;
            margin-bottom: 0;
            border-bottom: 1px solid #EFEFEF;
            background-color: $white;
            border-top-right-radius: .75rem;
            border-top-left-radius: .75rem;
        }
        .view-header {
            font-size: .85rem;
            font-weight: bold;
            letter-spacing: 0.42px;
            text-transform: uppercase;
            color: #939393;
            align-self: center;
        }
        .card-body {
            padding: 2rem;
            padding-bottom: 20px !important;
        }
        .card-footer {
            padding: 0.75rem 1.25rem;
            background-color: $white;
            border-top: 1px solid #EFEFEF;
            dd {
                margin-bottom: 0;
            }
        }
    }
    .card-edit {
        @include card-inner;
        padding: 1.5rem;
        margin-bottom: 1rem;
    }

}
@media (max-width: 575.98px) {
    .view-account-wrap {
        .card {
            .view-header {
                font-size: .65rem;
                font-weight: bold;
                letter-spacing: 0.42px;
                text-transform: uppercase;
                color: #939393;
                align-self: center;
            }
            .card-body {
                padding: 1rem;
            }
            .card-footer {
                padding: 0.5rem 1rem;
                background-color: $white;
                border-top: 1px solid #EFEFEF;
                dd {
                    margin-bottom: 0;
                }
            }
        }
    
    }
}