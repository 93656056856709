// .login-wrapper {
//     padding: 5rem;
//     .bg-login-left {
//         background: url(../../../img/login-bg.svg);
//         background-position: center center;
//           // background-size: contain;
//           background-repeat: no-repeat;
//           height: 450px;
//     }
//     .card {
//         @include card-inner;
//         border: 0;
//         .card-body {
//             padding: 2rem;
//         }
//     }
//     .form-link {
//         font-size: .875rem;
//         color: $blue-200;
//     }
//     .social-login {
//         ul {
//             .static-link-txt {
//                 color: $grey-700;
//                 font-size: .75rem;
//                 font-weight: 700;
//             }
//             margin-bottom: 0;
//             .social-link {
//                 color: $grey-700;
//                 font-size: .8rem;
//                 font-weight: 500;
//                 text-decoration: none;
//                 display: inline-flex;
//             }
//             .social-icon {
//                 width: 1rem;
//                 height: 1rem;
//                 display: inline-flex;
//                 align-items: center;
//                 align-content: center;
//                 &.facebook {
//                     background-image: url(../../../img/facebook.svg);
//                 }
//                 &.google {
//                     background-image: url(../../../img/google.svg);
//                 }
//             }

//         }

//     }
// }
// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {
//     .login-wrapper {
//         padding: 1rem;
//         .bg-login-left {
//             display: none;
//         }
//         .card {
//             .card-body {
//                 padding: 1.5rem;
//             }
//         }
//     }
// }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767.98px) {
//     .login-wrapper {
//         padding: 1rem;
//         .bg-login-left {
//             display: none;
//         }
//         .card {
//             .card-body {
//                 padding: 1.5rem;
//             }
//         }
//     }
// }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991.98px) {
//     .login-wrapper {
//         padding: 1rem;
//         .bg-login-left {
//             display: none;
//         }
//         .card {
//             .card-body {
//                 padding: 1.5rem;
//             }
//         }
//     }
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199.98px) {
//     .login-wrapper {
//         padding: 1.5rem;
//         .bg-login-left {
//             display: none;
//         }
//         .card {
//             .card-body {
//                 padding: 1.2rem;
//             }
//         }
//     }
// }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {

// }


.container-fluid {
    .bg-login-left {
        background: url(../../../img/login-bg.svg);
        background-position: center center;
          // background-size: contain;
          background-repeat: no-repeat;
          height: 450px;
    }
}
.login-wrapper {
    // padding: 5rem;
    background-color: #e5edf8;
    padding: 0 !important;

    .logo-container{
        // width:"174.5px" ;
        // height:"30px";
        // padding: 0 !important;
        margin-top: 3%;
        margin-left: 2px;;
       }
    .login-container{
        background-color:white;
        background-size: contain;
        border-radius:25px 0 0 25px;
    }
    .bg-login-left {
        background: url(../../../img/login-bg.svg);
        background-position: center center;
          // background-size: contain;
          background-repeat: no-repeat;
          height: 420px;
        //   width:500px;
    }
    .card {
        box-shadow:0px 0px 0px rgba(0, 0, 0, 0) !important;
        @include card-inner;
        // border: 0;
        border-radius:25px 0 0 25px;
        // .card-body {
        //     padding: 2rem;
        // }
        .card-body{
            font-size:small;
            border:0;
            margin:0;
            padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
        }
    }
    .form-link {
        font-size: .875rem;
        color: $blue-200;
    }
    .social-login {
        ul {
            .static-link-txt {
                color: $grey-700;
                font-size: .75rem;
                font-weight: 700;
            }
            margin-bottom: 0;
            .social-link {
                color: $grey-700;
                font-size: .8rem;
                font-weight: 500;
                text-decoration: none;
                display: inline-flex;
            }
            .social-icon {
                width: 1rem;
                height: 1rem;
                display: inline-flex;
                align-items: center;
                align-content: center;
                &.facebook {
                    background-image: url(../../../img/facebook.svg);
                }
                &.google {
                    background-image: url(../../../img/google.svg);
                }
            }

        }

    }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .login-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.5rem;
                font-size:small;
            border:0;
            margin:0;
            // padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .login-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
                padding: 1.5rem;
                font-size:small;
            border:0;
            margin:0;
            // padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .login-wrapper {
        padding: 1rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
            padding: 1.5rem;
            font-size:small;
            border:0;
            margin:0;
            // padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .login-wrapper {
        padding: 1.5rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
            padding: 1.2rem;
            font-size:small;
            border:0;
            margin:0;
            padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .login-wrapper {
        padding: 0rem;
        .bg-login-left {
            display: none;
        }
        .card {
            .card-body {
            padding: 8rem;
            font-size:small;
            border:0;
            margin:0;
            // padding:7.8rem;
            height:100vh;
            border-radius:25px 0 0 25px;
            }
        }
    }
}