body{
    font-family: 'Roboto', sans-serif;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    height: 100%;
}

a {
    &:focus {
        outline: none;
    }
}

// Main page wrapper
#wrapper {
    display: flex;
  }
  
  #wrapper #content-wrapper {
    background-color: #F5F5F5;
    width: 100%;
    overflow-x: hidden;
  }
  
  #wrapper #content-wrapper #content {
    flex: 1 0 auto;
  }

.dropdown {
    .dropdown-menu {
        font-size: 0.85rem;
        .dropdown-header {
            font-weight: 800;
            font-size: 0.65rem;
            color: #b7b9cc;
        }
    }
    &.no-arrow .dropdown-toggle::after {
        display: none;
    }
}
.primary-header {
    font-size: 1.4rem;
    color: $header-grey;
    letter-spacing: 0.045rem;
    margin-bottom: 0;
    line-height: 1.6;
}
.secondary-header {
    font-size: 1.5rem;
    color: #3C3C3C;
    text-transform: capitalize;
    margin-bottom: .5rem;
}
.view-header {
    font-size: .85rem;
    font-weight: bold;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    color: #939393;
    align-self: center;
}
.card-page-header {
    font-size: .85rem;
    font-weight: bold;
    letter-spacing: 0.42px;
    color: $grey-800;
    text-transform: uppercase;
    padding-bottom: .5rem;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 2rem;
}
dl {
    margin-bottom: 0;
}
.bg-gradient-primary {
    background: transparent linear-gradient(152deg, #244271 0%, #1B3A60 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 0px 5px #0000001D;
    background-size: cover;
    border-radius: 0 0 .5rem 0;
}
.back-page-link {
    font-size: .85rem;
    letter-spacing: 0.42px;
    color: #203C67;
}
.db-header {
    font-size: .85rem;
    color: #9A9A9A;
    letter-spacing: 0.56px;
    margin-bottom: .5rem;
    text-transform: uppercase;
    padding-bottom: .5rem;
    border-bottom: 1px solid #EAEAEA;
}
.dropdown-body {
    .b-b-0 {
        border-bottom: 0 !important;
    }
    padding: 1.25rem;
    .list-group-item {
        padding: 0.75rem 0;
    }
    .created-time {
        font-size: .85rem;
        color: #9A9A9A;
        margin-left: .5rem;
        text-transform: lowercase;
    }
    .list-time-header {
        font-size: .85rem;
        color: #9A9A9A;
        text-transform: uppercase;
    }
    .created-message {
        font-size: 1rem;
        color: #3C3C3C;
    }
    .created-txt {
        font-size: 1rem;
        color: #244170;
    }
}
.h-check {
    color: #244170;
}
.h-remove {
    color: #E06969;
}