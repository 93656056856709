
.custom-control{

	&.material-checkbox{
		--color: #26a69a;

		.material-control-input{
			display: none;
			&:checked~.material-control-indicator{
				// border-color: var(--color);
				//  transform: rotateZ(45deg) translate(3px, -5px);
				 background-image: url('../../../../../img/Circle03.svg');
				// width: 10px;
				// border-top: 0px solid #fff;
				// border-left: 0px solid #fff;
			}
		}
		.material-control-indicator{
			display: inline-block;
			position: absolute;		
			top: 4px;
			left: 0;
			width: 18px;
			height: 18px;
			// border: 2px solid #214B7D;
			transition: .3s;
			 background-image: url('../../../../../img/Circle01.svg')
		}
	}
  }
  .custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator{
    border-color: #1D9AC1
  }