.h-breadcrumb-1 {
    .breadcrumb {
        background-color: transparent;
        padding: 0;
        .breadcrumb-item  {
            font-size: .75rem;
            a {
                color: #244170;
                text-decoration: none;
            }
        }
        &.active {
            color: #A7A7A7;
        }
    }
    
}

