html {
    background-color: transparent;
}

.navbar {
    padding: 2.5rem 0;
}

.container-fluid {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.contentBody {
    background-color: $bgColor;
    width: 100%;
    // padding-bottom: 20px;
    min-height: 100vh;
}

.loginForm {
    & > div {
        width: 100%;
    }
}

.hekError {
    background: $hekErrorColor;
    color: $white;
    border-radius: 3px;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .icon {
        padding: 0px 5px;
    }
}
.ml1rem{
    margin-left: 1rem !important;
}

.fullScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: rgba(140, 109, 109, 0.48);
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1000;

    .progressCircle {
        color: #edf8fb;
    }
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f8f8f8 !important;
}

.MuiFormControl-root {
    .MuiInputBase-root.MuiInput-underline:hover::before {
        border-bottom: 1px solid !important;
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
        border-bottom: 1px solid #3f51b5 !important;
    }

    .MuiInputBase-root.MuiInput-underline.Mui-error:after {
        border-bottom: 1px solid #f44336 !important;
    }
}

.inputBox {
    & > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .MuiInputBase-input {
        font-size: 14px;
    }

    .MuiFormLabel-root {
        font-size: 14px;
    }
}

form {
    .must:after {
        content: "*";
        color: $hekErrorColor;
        padding-left: 3px;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
       


        & > * {
            margin-left: 6px;
        }
    }
}
.iconstyle {
    .label {
        text-align: center;
        i,
        img {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
}
.bodyContainer {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.hekmaGrid {
    .card {
        background-color: $bgColor;
        box-shadow: none;
        border-radius: 0px;
    }
    .MuiPaper-rounded {
        border-radius: 15px;
        box-shadow: 0px 0px 4px #00000015 !important;
    }

    .MuiTableHead-root {
        background-color: #f0f5ff;
    }

    // .MuiToolbar-gutters {
    //     padding: 20px;
    // }

    .MuiTableRow-head {
        th:first-child {
            padding-left: 20px !important;
        }

        th:last-child {
            padding-right: 20px !important;
        }

        th {
            background-color: #f0f5ff;
            padding: 8px 10px;
            color: $mgthColor;

            // text-transform: uppercase;
            & :last-child {
                svg {
                    display: none;
                }
            }
        }
    }

    .MuiTableRow-root {
        td.MuiTableCell-root {
            // padding: $cellPadding;
            padding: 17px 10px !important;
            // color: #213f6b !important;
        }

        td:first-child {
            padding-left: 20px !important;
        }
    }

    .MuiTableRow-root.MuiTableRow-footer {
        td.MuiTableCell-root {
            padding: 0px !important;
        }
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #dee2e6;
    }

    // tr:last-child {
    //     td {
    //         border-bottom: none;
    //     }
    // }

    .MuiFlatPagination-root {
        margin-top: 20px;
    }

    .hekmaGridBox {
        position: relative;

        .gridSearchField {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
            // margin-left: 20px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 15px;

            //border-bottom: 0.5px solid #9c9c9c;
            i {
                cursor: pointer;
                color: #c7c4c4;
            }

            svg {
                color: #9a9a9a;
            }
        }

        .MuiFormControl-root {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: #9c9c9c;
            }

            .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
                border-bottom: 1px solid #9c9c9c !important;
            }
        }

        // .MuiToolbar-gutters{
        //     margin-bottom: 20px;
        // }
    }
}

.siteuserstable {
    text-align: center;
    th:nth-child(3) {
        text-align: center !important;
    }
}
.fa-lg {
    font-size: 1.1rem !important;
}

.fullwidth {
    width: 100%;
}

.deleteIcon {
    color: #ce581b;
}

.delIconColor {
    color: #001440 !important;

    :hover {
        color: red !important;
    }
}

.hekmaDatePicker {
}

.panel-primary-wrap .card .card-page-header {
    margin-bottom: 1rem !important;
}

.siteNavsubbar .nav-item .nav-link {
    color: #9a9a9a;
    font-size: 0.9rem;
    // font-weight: bold;
    padding: 0.6rem 1rem;
}

#wrapper #content-wrapper {
    overflow-x: visible;
}

// .recentItems {
//     display: block;

//     a {
//         cursor: pointer;
//     }
// }

body.sidebar-toggled {
    .recentItems {
        display: none;
    }
}

.inputSearchBtn {
    border-radius: 0px 7px 7px 0px;
}

.siteNavsubbar {
    li.nav-item {
        margin: 0.6rem 1rem 0px 1rem;

        a.nav-link {
            padding: 0px 0px 0.3rem 0px;
        }
    }
}

#accordionSidebar.sidebar-dark {
    .nav-item {
        //line-height: 0px;
        a {
            //margin-bottom: 5px;
        }

        .nav-link.active {
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.23);
            i {
                color: #ffffff;
            }
        }
    }
}
.sponsormenu {
    img {
        margin-right: 5px !important;
    }
}

.counterSection {
    display: flex;
    justify-content: center;

    & > section {
        margin-left: 30px;
    }

    .subjectCount {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px solid;

        &.pscreening {
            border-color: #00a4fa;
        }

        &.screening {
            border-color: #8e54d1;
        }

        &.enroll {
            border-color: #f79967;
        }
    }

    .subText {
        margin-left: 10px;
    }
}

.c_study_component {
    // float:right;
    width: 33%;
    position: absolute;
}

.MuiInputAdornment-positionStart {
    margin-right: 8px !important;
}

.MuiTableRow-root {
}

// .posrelative{
//     position: relative;
//     .tpopover{
//         color: red;
//         position: absolute;
//         border: 1px solid red;
//         z-index: 99999;
//         background: #fff;
//         width: 110px;
//         left: -50px;
//         top: 22px;
//         display: none;
//     }
//     &.showPopover{
//         .tpopover{
//             display: block;
//         }
//     }
// }

.MuiTableRow-root {
    .posrelative {
        position: relative;
        cursor: pointer;

        .tpopover {
            color: #545490;
            position: absolute;
            // box-shadow: 1px 1px 4px 0px red;
            z-index: 99999;
            background: #fff;
            width: 110px;
            left: -50px;
            top: 22px;
            display: none;

            p {
                margin: 3px 2px;
                text-align: left;
                padding: 0px 5px;
                text-align: center;
                color: #545490;
                border-bottom: 1px dotted #ccc;
                font-weight: 500;

                &:first-child {
                    color: grey !important;
                    font-size: 10px !important;
                }

                &:first-child:hover {
                    background: none !important;
                }

                &:first-child:focus {
                    background: none !important;
                }
            }

            p:hover {
                background-color: lightgrey !important;
            }
        }

        &.showPopover {
            .tpopover {
                display: block;
            }
        }
    }

    &:last-child td:last-child {
        .tpopover {
            top: -60px;
        }
    }
}
.filedetails {
    .posrelative {
        position: relative;
        cursor: pointer;

        .tpopover {
            border: 1px solid #ccc;
            box-shadow: 0px 0px 4px #00000015 !important;
            color: #545490;
            position: absolute;
            // box-shadow: 1px 1px 4px 0px red;
            z-index: 100;
            background: #fff;
            width: 130px;
            left: -25px;
            top: 22px;
            font-size: 13px;
            display: none;
            text-align: left;

            a {
                margin: 3px 2px;
                text-align: left;
                padding: 0px 5px;
                // text-align   : center;
                color: #9e9ea5;
                border-bottom: 1px solid #ccc;
                display: block;
                text-decoration: none;
                // font-weight  : 500;

                // &:first-child {
                //     color    : grey !important;
                //     font-size: 10px !important;
                // }

                &:first-child:hover {
                    background: none !important;
                }

                &:first-child:focus {
                    background: none !important;
                }
                img {
                    padding-right: 5px;
                    padding-bottom: 4px;
                }
            }
            a:active {
                color: #1c3c63;
            }
            a:last-child {
                text-decoration: none;
                border: none;
            }
            a:hover {
                color: #1c3c63 !important;
                font-weight: 500;
            }
        }

        &.showPopover {
            .tpopover {
                display: block;
            }
        }
    }

    &:last-child td:last-child {
        .tpopover {
            top: -60px;
        }
    }
}

.emailcompose {
    div[class*="WAMuiChipInput-root-"] {
        position: relative;

        .MuiInputBase-root {
            position: static;
        }

        .MuiInput-root {
            position: static;
        }

        .MuiInputAdornment-positionStart {
            position: absolute;
            left: 0px;

            & + input {
                padding-left: 30px;
            }
        }

        .MuiChip-root:first-child {
            margin-left: 25px;
        }

        .MuiChip-root + div[class*="WAMuiChipInput-inputRoot-"] {
            input {
                padding-left: 0px !important;
            }
        }
    }

    //for live
    div[class*="jss"] {
        position: relative;

        .MuiInputBase-root {
            position: static;
        }

        .MuiInput-root {
            position: static;
        }

        .MuiInputAdornment-positionStart {
            position: absolute;
            left: 0px;

            & + input {
                padding-left: 30px;
            }
        }

        .MuiChip-root:first-child {
            margin-left: 25px;
        }

        .MuiChip-root + div[class*="MuiInput-root jss"] {
            input {
                padding-left: 0px !important;
            }
        }
    }
}

//medical history scss

.navbar {
    padding: 0.5rem 0;
}

.container-fluid {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.loginForm {
    & > div {
        width: 100%;
    }
}

.hekError {
    background: $hekErrorColor;
    color: $white;
    border-radius: 3px;
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .icon {
        padding: 0px 5px;
    }
}

.fullScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: rgba(140, 109, 109, 0.48);
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1000;

    .progressCircle {
        color: #edf8fb;
    }
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f8f8f8 !important;
}

.MuiFormControl-root {
    .MuiInputBase-root.MuiInput-underline:hover::before {
        border-bottom: 1px solid !important;
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
        border-bottom: 1px solid #3f51b5 !important;
    }

    .MuiInputBase-root.MuiInput-underline.Mui-error:after {
        border-bottom: 1px solid #f44336 !important;
    }
}

.inputBox {
    & > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .MuiInputBase-input {
        font-size: 14px;
    }

    .MuiFormLabel-root {
        font-size: 14px;
    }
}

form {
    .must:after {
        content: "*";
        color: $hekErrorColor;
        padding-left: 3px;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 14px;
        padding-right: 0px;
        margin-left:auto;

        & > * {
            margin-left: 6px;
        }
    }
}

.bodyContainer {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.hekmaGrid {
    .card {
        background-color: $bgColor;
        box-shadow: none;
        border-radius: 0px;
    }

    .MuiPaper-rounded {
        border-radius: 15px;
        box-shadow: 0px 0px 4px #00000015 !important;
    }

    .MuiTableHead-root {
        background-color: #f0f5ff;
    }

    // .MuiToolbar-gutters {
    //     padding: 20px;
    // }

    .MuiTableRow-head {
        th:first-child {
            padding-left: 20px !important;
        }

        th:last-child {
            padding-right: 20px !important;
        }

        th {
            background-color: #f0f5ff;
            padding: 8px 10px;
            color: $mgthColor;

            // text-transform: uppercase;
            & :last-child {
                svg {
                    display: none;
                }
            }
        }
    }

    .MuiTableRow-root {
        td.MuiTableCell-root {
            // padding: $cellPadding;
            padding: 17px 10px !important;
            // color: #213f6b !important;
        }

        td:first-child {
            padding-left: 20px !important;
        }
    }

    .MuiTableRow-root.MuiTableRow-footer {
        td.MuiTableCell-root {
            padding: 2px 0px !important;
        }
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #dee2e6;
    }

    // tr:last-child {
    //     td {
    //         border-bottom: none;
    //     }
    // }

    .MuiFlatPagination-root {
        margin-top: 20px;
    }

    .hekmaGridBox {
        position: relative;

        .gridSearchField {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 15px;

            //border-bottom: 0.5px solid #9c9c9c;
            i {
                cursor: pointer;
                color: #c7c4c4;
            }

            svg {
                color: #9a9a9a;
            }
        }

        .MuiFormControl-root {
            .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: #9c9c9c;
            }

            .MuiInputBase-root.Mui-focused.MuiInput-underline:after {
                border-bottom: 1px solid #9c9c9c !important;
            }
        }

        // .MuiToolbar-gutters{
        //     margin-bottom: 20px;
        // }
    }
}

.fa-lg {
    font-size: 1.1rem !important;
}

.fullwidth {
    width: 100%;
}

.deleteIcon {
    color: #ce581b;
}

.delIconColor {
    color: #001440 !important;

    :hover {
        color: red !important;
    }
}

.hekmaDatePicker {
}

.panel-primary-wrap .card .card-page-header {
    margin-bottom: 1rem !important;
}

.siteNavsubbar .nav-item .nav-link {
    color: #9a9a9a;
    font-size: 0.9rem;
    // font-weight: bold;
    padding: 0.6rem 1rem;
}

#wrapper #content-wrapper {
    overflow-x: visible;
}

body.sidebar-toggled {
    .recentItems {
        display: none;
    }
}

.siteNavsubbar {
    li.nav-item {
        margin: 0.6rem 1rem 0px 1rem;

        a.nav-link {
            padding: 0px 0px 0.3rem 0px;
        }
    }
}

/// Patient App

.pcard {
    padding: 20px;
    background: #fff;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 4px #00000015;
    height: 100%;
}

.roundedIcon50 {
    height: 50px !important;
    width: 50px !important;

    i {
        font-size: 20px !important;
    }
}

.detailsblock {
    font-size: $font-size-14;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    // .borderright {
    //     border-right: 1px solid gray;
    // }
    .borderright:after {
        content: "";
        position: absolute;
        top: 10%;
        right: 0;
        width: 2px;
        height: 80%;
        background: #f3efef;
    }
    .borderbottom:after {
        content: ""; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 98%; /* Change this to whatever width you want. */
        padding-top: 10px; /* This creates some space between the element and the border. */
        // padding-bottom: 10px;
        border-bottom: 2px solid #f3efef; /* This creates the border. Replace black with whatever color you want. */
    }
    .inline {
        text-align: center;
        display: flex;
        align-items: center;
        .trail {
            padding-right: 10px;
        }
        .traildata {
            padding-left: 5px;
            padding-right: 5px;
            font-weight: bold;
            font-size: 12px;
            margin-left: 10px;
            text-transform: uppercase;
            color: #1f3e67;
        }
        .trailcount {
            font-weight: bold;
            font-size: 16px;
        }
    }

    h6 {
        margin-bottom: 20px;
    }

    h5 {
        margin-bottom: 15px;
        border-bottom: 1px solid #244270;
        width: fit-content;
        font-weight: bold;
        color: #1f3e67;
    }

    p.row {
        margin-bottom: 10px;

        span:first-child {
            font-weight: 300;
        }

        span {
            font-weight: 500;
        }
    }

    .avatar {
        // background-color: #FFF0F0;
        border-radius: 8px;

        img {
            margin: 0 auto;
        }
    }
}

.dropdown-item:active {
    background-color: #b6cfff !important;
}

// .siteNavbar {
//     i {}

//     span {
//         margin-left: 5px;
//     }

//     a {
//         padding: 0px 0px 8px;
//         color  : #8B97A7;
//     }

//     .d_active {
//         color        : #1D3C64;
//         border-bottom: 4px solid;
//     }

//     .nav-item {
//         margin: 8px 16px 0px;
//     }
// }

.dashboardDetails {
    font-size: $font-size-14;

    .dcard {
        padding: 18px;
        background: #fff;
        border-radius: 0.75rem;
        box-shadow: 0px 0px 4px #00000015;
        height: auto;

        h6 {
            width: 100%;
        }

        p {
            width: 100%;
            margin: 0px;
        }

        .fc {
            display: flex;
            align-items: center;
            padding: 0 8px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: #fff0f0;
            }

            i {
                font-size: 20px;
            }
        }

        .sc {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .tc {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: -10px;

            i {
                font-size: 30px;
                opacity: 0.3;
            }

            span {
                i {
                    font-size: 15px;
                }
            }
        }

        .circular {
            div[class*="makeStyles-root"] {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .steps {
                position: relative;
                left: -25px;

                i {
                    transform: rotate(-77deg);
                }
            }

            span {
                margin-left: 5px;
            }

            &.bottomcircle {
                margin-top: 35%;
            }

            .label {
                margin-top: 8px;
            }
        }
    }

    .centeredAlign {
        display: flex;
        flex-direction: column;
        gap: 5%;
    }
    .dcol4main {
        display: flex;
        flex-wrap: wrap;
        height: 50%;

        .dcol4 {
            .row.body {
                min-height: 100px;
            }

            .body {
                i {
                    font-size: 80px;
                    color: red;
                }

                p {
                    width: 100%;
                }

                h3 {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .innerc {
        .row {
            min-height: 80px;
        }
    }

    .problemDetail {
        .description {
            background-color: #fff0f0;
            padding: 15px;
            border-radius: 6px;
        }
        h6 {
            color: #203f6a !important;
        }
        .problemDetailTable {
            hr {
                padding: 0px !important;
            }
            // div>button{
            //     padding:0px !important;
            //     padding-left: 5px !important;
            // }
            .MuiToolbar-regular {
                min-height: 0px !important;
            }
            .MuiTableRow-head {
                // th {
                //     background-color: #fff0f0;
                // }

                th:first-child {
                    border-radius: 5px 0px 0px 5px;
                }

                th:last-child {
                    border-radius: 0px 5px 5px 0px;
                }
            }

            .MuiTableCell-root {
                border-bottom: 0px;
                padding-top: 8px;
                padding-bottom: 8px;
            }

            .MuiTableCell-footer.MuiTablePagination-root {
                border-top: 1px solid #ccc;
            }
        }
    }

    .colType {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

.secondCircle {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.critical {
    span {
        position: relative;
    }

    & > div {
    }

    lable {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid #fff;
        display: inline-flex;
    }

    .severe {
        background: #d41818;
        // padding-top: 3px !important
    }

    .mild {
        background: #f9a60e;
        padding-top: 3px !important;
    }

    .moderate {
        background: #fd1f1f;
        padding-top: 3px !important;
    }
}
.pt-2 {
    padding-top: 2px !important;
}
.labResults {
    .labrow > div:last-child {
        border-bottom: 1px solid #ccc;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .titlerow {
        div:last-child {
            margin-left: 20px;
        }

        i {
            margin-right: 5px;
            color: #d0d0d0;
        }
    }

    .numbers {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    .unitread {
        position: relative;
        margin-top: 30px;

        section {
            position: relative;

            .reading {
                position: absolute;
                z-index: 100;
                top: -30px;
            }
        }

        i {
            position: absolute;
            color: #000;
            z-index: 100;
            top: -11px;
        }
    }
}

.linearWrapper {
    position: relative;

    .firstLinear {
        border-radius: 20px;
        height: 10px;

        .MuiLinearProgress-bar {
            background-color: #eee;
            transform: none;
        }
    }

    .secondLinear {
        position: absolute;
        border-radius: 20px;
        height: 10px;
        top: 0px;

        .MuiLinearProgress-bar {
            transform: translateX(-100%) !important;
        }
    }
}

//Slider start

.s_outerWidth {
    width: 100%;
    max-width: 100%;
    background: #d7ecf5;
    padding: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .left,
    .right {
        cursor: pointer;
    }

    .s_innerWidth {
        overflow: hidden;
        margin: 0 auto;

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: inline-flex;
            transition: all 0.5s;

            li {
                width: 150px;
                height: 150px;
                border: 1px solid red;
                margin: 0px 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}

//Slider End

.immunization {
    .s_outerWidth {
        background: transparent;
    }

    overflow: hidden;
    // margin: 0 14px;
    width: 100%;

    .left {
        position: relative;
        left: -18px;

        i {
            font-size: 20px;
            color: #23416f;
        }
    }

    .right {
        position: relative;
        right: -18px;

        i {
            font-size: 20px;
            color: #23416f;
        }
    }

    .s_outerWidth .s_innerWidth ul li {
        display: block;
        height: auto;
        border: 1px solid #ccc;
        padding: 15px;
        width: 200px;
        border: none;

        .daterow {
            .date {
                font-size: 40px;
                font-weight: 600;
                margin-right: 5px;
            }
        }

        .bottomText {
            margin-top: 10px;

            i {
                color: red;
            }

            span {
                color: #6e6e6e;
                margin-left: 5px;
                font-weight: 600;
            }

            div {
                color: #878787;
            }
        }

        .month {
            span {
                color: #878787;
            }
        }
    }

    &.diseaseDetail {
        font-size: 14px;

        .s_outerWidth .s_innerWidth ul li {
            padding: 0px;

            .dateDetail {
                margin-bottom: 5px;

                span {
                    margin-right: 5px;
                }

                .year {
                    font-size: 20px;
                    font-weight: 600;
                }

                .month {
                }

                .date {
                }
            }

            .diseasCard {
                background: #fff;
                border-radius: 5px;
                box-shadow: 0px 0px 4px #00000015;
                padding: 10px;

                div {
                    display: flex;
                    position: relative;
                }

                p {
                    margin-top: 15px;
                    border-top: 1px solid #ccc;
                    padding: 10px 0px;
                    margin-bottom: 0px;
                }

                .roundedIcon {
                    height: 50px;
                    width: 50px;

                    i {
                        font-size: 20px;
                    }
                }

                .fa-circle {
                    position: relative;
                    left: -8px;
                    font-size: 10px;
                    color: #5eed6f;
                }
            }
        }
    }
}

.centerline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 125%;

    p {
        border: 1px solid #cdf577;
        width: 100%;
    }

    span {
        position: absolute;
        background-color: #fff;
        padding: 0px 3px;
        margin-left: -35px;
    }
}

.mhsearchBox {
    // padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    .calenderIcon {
        padding: 7px 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 4px #00000015;
    }

    .textbox {
        width: 65%;
        display: flex;
        align-items: center;

        .MuiOutlinedInput-root {
            background-color: #fff;
            box-shadow: 0px 0px 4px #00000015;
        }
    }
}

.secondary-header {
    font-family: "Roboto Slab" !important;
}

.mediCard {
    background: #fff;
    padding: 15px;
    box-shadow: 0px 0px 1px #94bbe2;
    border-radius: 10px;
    margin: 20px 0px;
    font-size: $font-size-14;

    &:hover {
        transition: all 0.5s;
        box-shadow: 0px 0px 4px #999;
    }

    .top {
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
    }

    .rx {
        font-size: 20px;
        position: relative;
        left: -3px;
        top: 3px;
    }

    .label {
        color: #a6a6a6;
    }

    .MuiAccordion-root {
        border-radius: 0 !important;
        box-shadow: none;
    }

    .MuiAccordionSummary-root {
        padding: 0px;
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }

    .paneltitle {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;

        div {
            position: absolute;
            border-bottom: 1px solid #ccc;
            width: 103%;
        }

        span {
            background: #fff;
            z-index: 100;
            padding-right: 10px;
        }
    }

    .fa-syringe {
        font-weight: 600;
        color: #bd2121;
    }
}

.roundedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff0f0;
}

.vyear {
    border-bottom: 1px solid #ccc;
    margin: 20px 0px 0px;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.diseaseTopBar {
    font-size: $font-size-14;

    .totaldisease {
        background: #fff;
        padding: 15px;
        box-shadow: 0px 0px 4px #00000015;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 175px;

        .roundedIcon {
            height: 50px;
            width: 50px;

            i {
                font-size: 20px;
            }
        }

        .number {
            font-size: 30px;
            font-weight: 600;
        }
    }

    .status {
        .statuscard {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px 0px 0px 20px;
            border-right: 1px solid #ece3e3;
            padding-right: 20px;

            &:last-child {
                border: none;
            }

            .fa-circle {
                color: #5eed6f;
                font-size: 10px;
            }

            .number {
                font-size: 18px;
            }
        }
    }
}

.vaccineCard {
    .daterow {
        margin-bottom: 10px;
    }
}

.labDetailPage {
    font-size: 14px;
}

.labTableRow {
    border-bottom: 1px solid #ccc;
    margin: 20px 0px;
}

.dateDetail {
    .roundedIcon {
        background-color: #fff;
        z-index: 100;
        position: relative;
    }

    .year {
        font-weight: 600;
        font-size: 20px;
    }
}

.testNameDetail {
    border-left: 10px solid #863dd0;
    justify-content: space-between;
    color: #863dd0;
    cursor: pointer;

    i {
        font-size: 18px;
    }
}

.datedescription {
    max-height: 500px;
    overflow: auto;
    .iconcontainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 40px;

        .dash {
            border: 1px dashed blue;
            width: 230%;
            position: absolute;
            left: -15px;
        }

        .vdash {
            border-left: 1px dashed blue;
            height: 135%;
            position: absolute;
        }
    }

    .row:first-child {
        .iconcontainer {
            .vdash {
                top: 0px;
            }
        }
    }

    .row:last-child {
        .iconcontainer {
            .vdash {
                bottom: 0px;
            }
        }
    }
}

.allergiesPage {
    .iconcontainer {
        position: relative;

        .dash {
            border: 1px dashed #d03d65;
            width: 415%;
            left: -250%;
        }

        .vdash {
            border-left: 1px dashed #d03d65;
            height: 135%;
        }
    }

    .testNameDetail {
        border-left: 10px solid #d03d65;
        justify-content: space-between;
        color: #d03d65;
        cursor: pointer;
    }

    .dateColumn {
        position: relative;
        z-index: 1000;
        background-color: $bgColor;
        padding: 0px 10px;
    }

    .descDetail {
        border-top: 1px solid #ccc;
    }
}

.studyProgress {
    .bottomborder {
        border-bottom: 1px solid #ccc;
    }

    #dstudySlider {
        border-radius: 5px;
        background: #fff;

        .s_innerWidth {
            padding: 5px;
            border-radius: 5px;
            background: #f8f8f8;
        }

        li {
            width: 100px;
            height: 30px;
            border: none;
            flex-direction: column;
        }

        .centerline {
            span {
                margin: 0px;
                background: #f8f8f8;
                padding: 0px;
            }

            p {
                margin: 0px;
                border: 2px solid #48e6aa;
            }
        }
    }
}

.height100 {
    height: 100%;
}

/* PIR CSS Start*/

.dashboardContainer {
    font-size: 14px;
    margin-top: 15px;

    .hekmaGrid .card {
        background-color: #fff;
    }

    .text {
        text-anchor: middle;
    }

    .circle {
        fill: orange;
        stroke: orange;
        fill-opacity: 0.5;
    }

    .axis line {
        fill: none;
        stroke: #ddd;
        shape-rendering: crispEdges;
    }

    .axis path {
        fill: none;
    }

    .axis text {
        font-size: 0.7em;
        fill: #555;
        font-family: sans-serif;
    }

    .focus {
        fill: none;
        stroke: green;
    }

    .focus circle {
        fill: #f1f3f3;
        stroke: #6f257f;
        stroke-width: 5px;
    }

    .hover-line {
        stroke: #6f257f;
        stroke-width: 2px;
        stroke-dasharray: 3, 3;
    }

    .dashboardTile {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
        height: 100%;
    }

    .searchCriteriaPanel {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
    }

    .dtitle {
    }

    .pircontent {
        padding: 15px;
        background-color: #eaecf0;
        border:1px solid #d7dce4;
        border-radius: 10px;

        &.form {
            background-color: #fff;
        }
    }

    textarea {
        border: none;
        width: 100%;
        height: 250px;
        font-size: 14px;
        line-height: 20px;

        &:focus {
            outline: none !important;
        }
    }

    .pirtitle {
        font-weight: 600;
    }

    .iconPlace {
        display: flex;
        align-items: center;

        span.icon {
            padding: 5px;
            display: flex;
            border: 1px solid #bdb627;
            margin-right: 10px;
            border-radius: 5px;
            color: #bdb627;
            background-color: #f5f6f7;

            i.ic {
                color: #bdb627;
                transform: rotate(45deg);
            }

            i.ec {
                color: #bdb627;
                transform: rotate(-135deg);
            }
        }
    }

    .searchcriteriacontainer {
        .pircontent {
            height: 100%;
        }
    }

    .entities {
        .colcount {
            // column-count: 2;
        }

        .MuiChip-root {
            background-color: #f7ecec;
            display: grid;
            // color           : #fff;
            color: #ff1a1a;
        }

        .inclusion {
            .MuiChip-root {
                //background-color: #7eda7e;
                color: #048804;
            }
        }
    }

    .avatar {
        width: 100px;
        height: 100px;
        background: #e8d7d7;
    }
}

.menucontainer {
    .pirmenubtn {
        padding: 8px 15px;
        min-width: 150px;
        color: #fff;
        border-radius: 25px;
        text-align: center;
        background: #21406c;
        background: -moz-linear-gradient(left, #21406c 0%, #21406c 0%, #e199cb 100%);
        background: -webkit-linear-gradient(left, #21406c 0%, #21406c 0%, #e199cb 100%);
        background: linear-gradient(to right, #21406c 0%, #21406c 0%, #e199cb 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#21406c', endColorstr='#e199cb', GradientType=1);
    }

    .withbar {
        &.piractive {
            .bar {
                color: #e199cb;
            }

            .whitebtn {
                color: #fff;
                text-align: center;
                background: #21406c;
                background: -moz-linear-gradient(left, #21406c 0%, #21406c 0%, #e199cb 100%);
                background: -webkit-linear-gradient(left, #21406c 0%, #21406c 0%, #e199cb 100%);
                background: linear-gradient(to right, #21406c 0%, #21406c 0%, #e199cb 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#21406c', endColorstr='#e199cb', GradientType=1);
            }
        }

        .whitebtn {
            padding: 8px 15px;
            min-width: 150px;
            border-radius: 25px;
            background: #fff;
            text-align: center;
        }
    }

    .bar {
        color: #fff;
        width: 85px;
        border-bottom: 10px solid;
        //#79b7e7 bar color
    }
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    min-width: 500px;
}

.radioBox {
    .MuiFormGroup-root {
        flex-direction: row;
    }
}

.form-control-plaintext.viewrowp {
    padding: 0px;
}

.pirdashboards {
    .dtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .line {
        fill: none;
        stroke: #000;
        stroke-width: 1.5px;
    }

    .area {
        fill: #969696;
    }

    /* .bar {
        fill: steelblue;
  } */

    .bar:hover {
        fill: brown;
    }

    .x.axis path {
        /* display: none; */
        color: #333333;
    }

    #language .x.axis text {
        text-anchor: end;
    }

    #language svg {
        overflow: visible;
    }

    body {
        font-family: "Roboto", sans-serif;
        color: #333333;
    }

    /* Add shadow effect to chart. If you don't like it, get rid of it. */
    svg {
        -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
    }

    /*Styling for the lines connecting the labels to the slices*/
    polyline {
        opacity: 0.3;
        stroke: black;
        stroke-width: 2px;
        fill: none;
    }

    /* Make the percentage on the text labels bold*/
    .labelName tspan {
        /* font-style: normal; */
        font-weight: 600;
        font-size: 22px;
    }

    .labelName {
        font-size: 0.9em;
        font-weight: 600;
        font-size: 22px;
        text-transform: capitalize;
    }

    #race svg {
        z-index: 9;
        display: block;
        margin: auto;
    }

    h1,
    h2 {
        text-align: center;
        font-weight: 600;
        margin: auto;
        padding: 50px;
        /* border  : 3px solid green; */
    }

    h3 {
        text-align: center;
        font-style: italic;
        margin: auto;
        /* padding: -200 0 20 0px; */
        margin-top: -40px;
        font-size: 14px;
    }

    * {
        font-size: 12px;
    }

    .subheader {
        font-size: 16px;
        text-align: center;
        margin-top: -40px;
    }

    .states :hover {
        fill: red;
    }

    .states {
        stroke: #aaa;
        stroke-width: 0.5px;
        stroke-linejoin: round;
        stroke-linecap: round;
    }

    .state-borders {
        fill: none;
        stroke: #aaa;
        stroke-width: 0.5px;
        stroke-linejoin: round;
        stroke-linecap: round;
        pointer-events: none;
    }

    .map-facet {
        float: left;
    }

    .nodatafound {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        font-size: 15px;
    }
}

.globalsearch .form-control:focus {
    border-color: #94bbe2 !important;
    box-shadow: none;
}

.entityAutoform {
    .ageSelected {
        background-color: #e0e0e0;
    }
}

.entityContainer {
    .col-sm-6 {
        & > div {
            margin-top: 20px;
        }

        & > div:first-child {
            margin-top: 0px;
        }
    }

    .MuiAccordionDetails-root {
        p.MuiTypography-root {
            width: 100%;

            .sortableentities {
                & > div {
                    display: flex;
                    flex-wrap: wrap;

                    & > div {
                        padding: 0px 6px;
                    }
                }
            }
        }
    }

    .MuiChip-root {
        background-color: #f7ecec;
        color: #ff1a1a;
        height: auto;
        min-height: 32px;

        .MuiChip-label {
            white-space: break-spaces;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .inclusion {
        .MuiChip-root {
            color: #048804;
        }
    }
}

/* PIR CSS End */

.headerfontcss {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 500;
}

//patient 360
.pdtail {
    h6 {
        color: #1f3e67;
    }
}
.tcenter {
    text-align: center !important;
}
.plr-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.pl-5 {
    padding-left: 5px !important;
}
.pr-5 {
    padding-right: 5px !important;
}

//doctor dashboard
.dashboardtable {
    .MuiPaper-rounded {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
    .MuiToolbar-regular {
        min-height: 0px !important;
    }
    .MuiTableHead-root {
        background-color: none !important;
    }
    table thead {
        th:first-child {
            border-top-left-radius: 20px !important;
        }
        th:last-child {
            border-top-right-radius: 20px !important;
        }
    }
}
.pwdstrength {
    li {
        font-size: 14px !important;
    }
}

.viewrecord {
    cursor: pointer;
    span:hover {
        color: #2b78d4;
    }
}
.orderText{
    color:gray
}