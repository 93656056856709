.topbar {
    height: $topbar-base-height;
    #sidebarToggleTop {
        height: 2.5rem;
        width: 2.5rem;
        &:hover {
            background-color: $grey-100;
        }
        &:active {
            background-color: $grey-200;
        }
        &.btn {
            font-size: 1rem;
        }
        &.btn-link {
            color: $blue-100;
        }
    }
    .navbar-search {
        width: 25rem;
        input {
            font-size: 0.85rem;
            height: auto;
        }
        .form-control {
            border-color: #F2F2F2;
        }
    }
    .nav-item {
        .nav-link {
            height: $topbar-base-height;
            display: flex;
            align-items: center;
            padding: 0 0.75rem;
            position: relative;
            &:focus {
                outline: none;
            }
            .img-profile {
                height: 2rem;
                width: 2rem;
            }
            .badge-counter {
                position: absolute;
                transform: scale(0.7);
                transform-origin: top middle;
                right: .5rem;
                margin-top: -.15rem;
                border-radius: .5rem;
                background-color: $orange-primary;
                font-size: .7rem;
            }
            .user-text {
                font-size: .75rem;
                color: $blue-100;
                font-weight: bold;
            }
        }
        &:focus {
            outline: none;
        }
        &.dropdown {
            .dropdown-toggle {
                &::after {
                    width: 1rem;
                    text-align: center;
                    float: right;
                    vertical-align: 0;
                    border: 0;
                    font-weight: 900;
                    content: '\f107';
                    font-family: 'Font Awesome 5 Free';
                    cursor:pointer;
                }

            }
            &.show {
                .dropdown-toggle{
                    &::after{
                        content: '\f106';
                    }
                }
            }
        }
    }
    .dropdown {
        position: static;
        .dropdown-menu {
            width: calc(100% - 1.5rem);
            right: 0.75rem;
        }
    }
    &.bg-header-shadow {
        box-shadow: 0 .188rem .375rem #00000005;
        border-radius: 0 0 1.5rem .25rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
    }
}
@media (min-width: 576px) {
    .topbar .dropdown {
      position: relative;
    }
    .topbar .dropdown .dropdown-menu {
      width: auto;
      right: 0;
      cursor:pointer;
    }
    .topbar .dropdown-list {
      width: 20rem !important;
    }
    .topbar .dropdown-list .dropdown-item .text-truncate {
      max-width: 13.375rem;
    }
}
  
// @media (min-width: 576px) {
//     .navbar-nav {
//         .form-control {
//             border-color: #F2F2F2;
//         }
//     }
//     .dropdown {
//         position: relative;
//         .dropdown-menu {
//             width: auto;
//             right: 0;
//         }
//     }
// }
.topbar.navbar-light {
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: $blue-600;
                &:hover {
                    color: $blue-600;
                }
                &:active {
                    color: $blue-600;
                }
            }
        }
    }
}
