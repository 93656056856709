.app-simple.light {
  // --app-background: #ffffff;
  --app-color: #585858;
  --app-avatar-background: #f0f3f7;
  --app-info-color: #9b9b9b;
  --app-info-border: #d7e0e9;
  --app-chat-background: #f0f3f7;
  --app-people-color: gray;
  --app-people-active-color: #5babfc;

  .pn-msg-input {
    --msg-input__background: transparent;
    --msg-input__send__background: #5babfc;
  }

  .pn-msg-list--light {
    --msg-list__background: transparent;
    --msg--hover__background: #e5e8ec;
  }

  .pn-channel-list--light {
    --channel-list__background: #ffffff;
    --channel--active__background: #f0f3f7;
    --channel--hover__background: #5babfc;
    --channel__name__color: #585858;
    --channel--active__name__color: #585858;
  }
}

.app-simple.dark {
  --app-background: #292b2f;
  --app-color: #ffffff;
  --app-avatar-background: #414348;
  --app-info-color: #9b9b9b;
  --app-info-border: #414348;
  --app-chat-background: #2f3136;
  --app-people-color: gray;
  --app-people-active-color: #de2440;

  .pn-msg-input {
    --msg-input__send__background: #de2440;
  }

  .pn-msg-list--dark {
    --msg-list__background: transparent;
  }

  .pn-channel-list--dark {
    --channel-list__background: #292b2f;
    --channel--active__background: #2f3136;
    --channel--hover__background: #de2440;
    --channel__name__color: #9b9b9b;
    --channel--active__name__color: #9b9b9b;
  }
}

.app-simple {
  background: var(--app-background);
  color: var(--app-color);
  display: flex;
  height: 80vh;
  overflow: hidden;

  .pn-msg-input {
    --msg-input__send__borderRadius: 25px;
    --msg-input__send__padding: 10px 20px;
    --msg-input__textarea__borderRadius: 25px;
    --msg-input__textarea__padding: 12px 24px;
  }

  .pn-channel-list {
    --channel__description__display: none;
    --channel__name__fontSize: 13px;
    --channel__name__fontWeight: 400;
    --channel__padding: 12px 24px;
  }

  .pn-member-list {
    --member__padding: 9px 0;
    --member__name__margin: 8px 0 0;
    --member__title__display: none;
    --member--hover__background: transparent;
    margin: 1rem;
  }

  .pn-msg-list {
    --member__padding: 9px 0;
    --member--hover__background: transparent;
  }

  .toggle {
    margin-top: 50px;
    position: relative;

    span {
      font-size: 12px;
      font-weight: bold;
      margin-left: 24px;
    }

    button {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .channels {
    background: var(--app-background);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 300px;

    h4 {
      margin: 34px 24px 12px;
    }
  }

  .user {
    display: flex;
    padding: 46px 24px 20px;

    h4 {
      flex-grow: 1;
      margin: 8px 0;
    }

    img {
      background: var(--app-avatar-background);
      display: block;
      margin: 0 24px 0 0;
      width: 36px;
      height: 36px;
      border-radius: 36px;
    }
  }

  .info {
    padding: 24px 24px 0;

    h4 {
      margin: 0 0 2px;
    }

    small {
      color: var(--app-info-color);
    }

    hr {
      background-color: var(--app-info-border);
      border: 0;
      height: 1px;
      margin: 18px 0 0;
    }
  }

  .chat {
    background: var(--app-chat-background);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // margin: 30px 30px 30px 0;
    padding-bottom: 30px;
    position: relative;
  }

  .members {
    background: var(--app-background);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transform: translateX(310px);
    transition: 0.3s ease-in-out;
    width: 0;

    &.shown {
      transform: none;
      width: 200px;
    }

    h4 {
      margin-top: 54px;
    }

    .close {
      margin-right: 24px;
    }
  }

  .close {
    cursor: pointer;
    display: none;
    float: right;
  }

  .hamburger {
    display: none;
    float: left;
    font-size: 24px;
    margin-right: 15px;
  }

  .people {
    align-items: center;
    color: var(--app-people-color);
    cursor: pointer;
    display: flex;
    font-size: 13px;
    position: absolute;
    right: 34px;
    top: 34px;
    z-index: 1;

    &.active {
      color: var(--app-people-active-color);
    }

    span {
      margin: 2px 8px 0 0;
    }
  }

  @media (max-width: 950px) {
    .channels,
    .members.shown {
      width: 200px;
    }
  }

  @media (max-width: 700px) {
    .channels {
      height: 100%;
      position: absolute;
      z-index: 10;
      transition: 0.3s ease-in-out;
      width: 100%;

      &:not(.shown) {
        transform: translateX(-100%);
      }
    }

    .user {
      padding-top: 24px;
    }

    .close {
      display: inline-block;
    }

    .chat {
      margin: 5px;
    }

    .hamburger {
      display: inline;
    }

    .members.shown {
      height: 100%;
      padding: 24px;
      position: absolute;
      z-index: 10;
      width: 100%;

      h4 {
        margin-top: 0;
      }

      h4 span {
        margin-right: 48px;
      }
    }
  }
}
.chatfooter {
  padding: 0.5rem 0.5rem !important;
  display: inline-flex;
  border-bottom-left-radius:6px !important;
  border-bottom-right-radius:6px !important;
  border: 1px solid #e1e1e1;
  .MuiOutlinedInput-multiline {
    padding: 12.5px 12px !important;
    border-radius: 12px;
  }
  // .MuiFormControl-root .MuiTextField-root{
  //   width:"91%"
  // }
}
.chat {
  .card-body {
    border: 1px solid #e1e1e1;
  }
}
.header {
  background-color: #977fd0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding: 13px 20px 10px 20px;
  color:white
}
.getEmojiButton{
  float: right;
  border: none;
  margin: 0;
  font-size:15px;
  padding-top: 6px;
  padding-left: 7px;
  cursor: pointer
}
.emojiPicker {
  position: absolute;
  // bottom: 10;
  top:15px;
  right: 0;
  float: right;
  margin-left: 200px;
}